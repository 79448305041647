import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "antd";

import { useCompaniesColumns } from "components/company/companiesDataTableColumns";
import { TableWrapper } from "container/styled";

import { CompaniesApi } from "api/api";
import openNotificationWithIcon from "utility/notification";
import { useTranslation } from "react-i18next";
import { useCompaniesFetch } from "./hooks/useCompaniesFetch";
import { useDebouncedValue } from "hooks/useDebouncedValue";
import { useGetRoles } from "../../hooks/useGetRoles";
import { CompanyTypeEnum } from "../../api";

const companiesApi = new CompaniesApi();

const CompaniesTable = ({
  searchCompanies,
  filterType,
  companyType,
  rowKey = "id",
}: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const roles = useGetRoles();

  const searchValue = useDebouncedValue({ delay: 300, value: searchCompanies });

  const { data, isLoading, fetchCompanies, query, setQuery } =
    useCompaniesFetch({
      searchCompanies: searchValue,
      filterType,
      companyType,
    });

  const handleViewDashboardClick = (item: any) => {
    navigate(`/dashboard/${item.id}`);
  };

  const handleViewClick = (item: any) => {
    let path = "";

    switch (companyType) {
      case CompanyTypeEnum.NUMBER_0:
        path = "transporter";
        break;
      case CompanyTypeEnum.NUMBER_1:
        path = "client";
        break;
      case CompanyTypeEnum.NUMBER_2:
        path = "freight-forwarder";
        break;
      case CompanyTypeEnum.NUMBER_3:
        path = "shipper";
        break;
      default:
        path = "transporter";
        break;
    }
    navigate(`/add-${path}?companyId=${item.id}&upsertMode=false`);
  };

  const handleEditClick = (item: any) => {
    let path = "";

    switch (companyType) {
      case CompanyTypeEnum.NUMBER_0:
        path = "transporter";
        break;
      case CompanyTypeEnum.NUMBER_1:
        path = "client";
        break;
      case CompanyTypeEnum.NUMBER_2:
        path = "freight-forwarder";
        break;
      case CompanyTypeEnum.NUMBER_3:
        path = "shipper";
        break;
      default:
        path = "transporter";
        break;
    }
    navigate(`/add-${path}?companyId=${item.id}&upsertMode=true`);
  };

  const handleDeleteClick = async (item: any) => {
    try {
      await companiesApi.apiCompaniesIdDelete({ id: item.id });

      openNotificationWithIcon(
        "success",
        t(
          "companyModal:company-delete-success",
          "Company is deleted successfully!"
        )
      );
      await fetchCompanies();
    } catch (error) {
      console.log("error : ", error);
      openNotificationWithIcon(
        "error",
        t("companyModal:company-delete-error", "Error deleting company")
      );
    }
  };

  const columns = useCompaniesColumns({
    handleViewClick,
    handleViewDashboardClick,
    handleEditClick,
    handleDeleteClick,
    companyType,
  });

  const handlePaginationChange = (pageNumber: number) => {
    setQuery((prevQuery) => ({ ...prevQuery, pageNumber }));
  };

  const handleShowSizeChange = (pageNumber: number, pageSize: number) => {
    setQuery((prevQuery) => ({ ...prevQuery, pageNumber, pageSize }));
  };

  useEffect(() => {
    fetchCompanies();
  }, [query, searchValue, filterType]);

  return (
    <TableWrapper className="table-responsive">
      <Table
        loading={isLoading}
        dataSource={data?.items}
        columns={columns}
        showSorterTooltip={false}
        rowKey={(record: any) => record[rowKey || "id"]}
        pagination={{
          current: data?.pageIndex,
          total: data?.totalCount,
          showSizeChanger: true,
          pageSizeOptions: [10, 50, 100, 1000],
          pageSize: query.pageSize,
          onChange: handlePaginationChange,
          onShowSizeChange: handleShowSizeChange,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
    </TableWrapper>
  );
};

export default CompaniesTable;
