import { Col, Form, Input, Popconfirm, Row, Select, Table } from "antd";
import { Button } from "components/buttons/buttons";
import { Cards } from "components/cards/frame/cards-frame";
import { Modal } from "components/modals/antd-modals";
import { PageHeader } from "components/page-headers/page-headers";
import {
  AddProfile,
  BasicFormWrapper,
  CardToolbox,
  Main,
  ProfilePageHeaderStyle,
  ProfileTableStyleWrapper,
  TableWrapper,
} from "container/styled";
import FeatherIcon from "feather-icons-react";
import { useAuthorization } from "hooks/useAuthorization";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import openNotificationWithIcon from "utility/notification";
import { CountriesApi, CustomOfficesApi } from "../../api";
import { useCompanyId } from "utility/useCompanyId";

const customOfficesApi = new CustomOfficesApi();
const countriesApi = new CountriesApi();

// const CODE_BOOK_TYPE_COUNTRY = "CODE_BOOK_TYPE_COUNTRY";

const initialFormValues = {
  id: 0,
  name: "",
  address: "",
  zipCode: "",
  countryId: "",
};

const CustomOffices = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { hasPermission } = useAuthorization();
  const companyId = useCompanyId();

  const [state, setState] = useState({
    selectedRowKeys: 0,
    selectedRows: 0,
    visible: false,
    editVisible: false,
    modalType: "primary",
    customOffice: initialFormValues,
    loading: true,
  });

  const [customOffices, setCustomOffices] = useState([]);
  const [countries, setCountries] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 10,
    search: "",
    sortBy: "",
    sortType: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setState({ loading: true });
    await fetchCustomOffices();
    await fetchCountries();
    setState({ loading: false });
  };

  const fetchCustomOffices = async () => {
    try {
      const { data } = await customOfficesApi.apiCustomOfficesGet(query);
      setCustomOffices(Array.isArray(data.items) ? data.items : []);
    } catch (error) {
      setCustomOffices([]);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await countriesApi.apiCountriesGet({
        pageSize: 1000,
        pageNumber: 1,
        search: "",
      });
      setCountries(response.data.items || []);
    } catch (error) {
      setCountries([]);
    }
  };

  const showModal = () => {
    setState({
      ...state,
      visible: true,
    });

    form.resetFields();
  };

  const showEditModal = (customOffice) => {
    setState({
      ...state,
      editVisible: true,
      customOffice,
    });
    form.setFieldsValue(responseToFormModel(customOffice));
  };

  const responseToFormModel = (model) => {
    return {
      id: model.id,
      name: model.name,
      zipCode: model.zipCode,
      address: model.address,
      countryId: model.countryId,
    };
  };

  const handleCancel = () => {
    onCancel();
  };

  const onCancel = () => {
    setState({
      ...state,
      visible: false,
      editVisible: false,
    });
  };

  const handleSubmit = async (values) => {
    try {
      setSubmitLoading(true);

      const response = await customOfficesApi.apiCustomOfficesPost({
        name: values?.name,
        address: values?.address,
        countryId: values?.countryId,
        zipCode: values?.zipCode,
      });
      if (response.status === 200) {
        openNotificationWithIcon(
          "success",
          t("custom-offices.success.add", "Custom Office created successfully")
        );
        await fetchData();
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon(
        "error",
        t("custom-offices.failure.add", "Custom Office creation failed")
      );
    } finally {
      setSubmitLoading(false);
      onCancel();
    }
  };

  const handleEditSubmit = async (values) => {
    try {
      setSubmitLoading(true);
      await customOfficesApi.apiCustomOfficesIdPut({
        id: values?.id,
        name: values?.name,
        address: values?.address,
        countryId: values?.countryId,
        zipCode: values?.zipCode,
      });
      openNotificationWithIcon(
        "success",
        t("custom-offices.success.edit", "Custom Office updated successfully")
      );
      onCancel();

      fetchData();
    } catch (error) {
    } finally {
      setSubmitLoading(false);
    }
  };

  const onDeleteConfirm = async (id) => {
    try {
      await customOfficesApi.apiCustomOfficesIdDelete({ id });
      fetchData();
    } catch (error) {}
  };

  const renderCountries = () => {
    return countries.map((country) => (
      <Select.Option key={country.id} value={country.id}>
        {country.name}
      </Select.Option>
    ));
  };

  const customOfficesTableData = customOffices.map((office) => {
    const { id, name, address, zipCode, countryName } = office;

    return {
      key: id,
      name,
      address,
      zipCode,
      country: countryName,
      action: (
        <div
          key={id}
          className="table-actions"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button className="btn-icon" type="primary" to="#" shape="circle">
            <Link onClick={() => showEditModal(office)} to="#">
              <FeatherIcon icon="edit" size={16} />
            </Link>
          </Button>
          {hasPermission("custom-offices:delete") ? (
            <>
              <Popconfirm
                title={t(
                  "custom-offices.delete-office-confirmation",
                  "Are you sure delete this custom office?"
                )}
                onConfirm={() => onDeleteConfirm(id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  className="btn-icon"
                  type="danger"
                  to="#"
                  shape="circle"
                >
                  <FeatherIcon icon="trash-2" size={16} />
                </Button>
              </Popconfirm>
            </>
          ) : null}
        </div>
      ),
    };
  });

  const columns = [
    {
      title: t("global.name", "Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("global.address", "Address"),
      dataIndex: "address",
      key: "address",
    },
    {
      title: t("global.zipCode", "ZipCode"),
      dataIndex: "zipCode",
      key: "zipCode",
    },
    {
      title: t("global.country", "Country"),
      dataIndex: "country",
      key: "country",
    },
    {
      title: t("global.actions", "Actions"),
      dataIndex: "action",
      key: "action",
      width: "90px",
    },
  ];

  return (
    <>
      <CardToolbox>
        <ProfilePageHeaderStyle>
          <PageHeader
            ghost
            title={t("custoom-offices.title", "Custom Offices")}
            buttons={[
              hasPermission("custom-offices:add") && (
                <Button
                  onClick={showModal}
                  className="btn-add_new"
                  size="default"
                  type="primary"
                  key="add-office"
                >
                  {t("custom-offices.add", "Add New Custom Office")}
                </Button>
              ),
            ]}
          />
        </ProfilePageHeaderStyle>
      </CardToolbox>

      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <ProfileTableStyleWrapper>
                <div className="contact-table">
                  <TableWrapper className="table-responsive">
                    <Table
                      loading={state.loading}
                      dataSource={customOfficesTableData}
                      columns={columns}
                      rowKey="key"
                      pagination={{
                        current: customOffices?.pageIndex,
                        total: customOffices?.totalCount,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "50", "100", "1000"],
                        onChange: (pageNumber, pageSize) =>
                          setQuery((prev) => ({
                            ...prev,
                            pageNumber,
                            pageSize,
                          })),
                        showTotal: (total, range) =>
                          `${range[0]}-${range[1]} of ${total} items`,
                      }}
                    />
                  </TableWrapper>
                </div>
              </ProfileTableStyleWrapper>
            </Cards>
          </Col>
        </Row>
        <Modal
          type={state.modalType}
          title={t("custom-offices.modal.title", "Add New Custom Office")}
          visible={state.visible}
          footer={null}
          onCancel={handleCancel}
        >
          <div className="project-modal">
            <AddProfile>
              <BasicFormWrapper>
                <Form form={form} name="customOffice" onFinish={handleSubmit}>
                  <Form.Item
                    label="Id"
                    name="companyId"
                    hidden
                    initialValue={companyId}
                  >
                    <Input placeholder="Id" />
                  </Form.Item>

                  <Form.Item label={t("global.name", "Name")} name="name">
                    <Input placeholder={t("global.name", "Name")} />
                  </Form.Item>

                  <Form.Item
                    label={t("global.address", "Address")}
                    name="address"
                  >
                    <Input placeholder={t("global.address", "Address")} />
                  </Form.Item>

                  <Form.Item
                    label={t("global.zipCode", "ZipCode")}
                    name="zipCode"
                  >
                    <Input placeholder={t("global.zipCode", "ZipCode")} />
                  </Form.Item>

                  <Form.Item
                    label={t("global.countryId", "Country")}
                    name="countryId"
                    rules={[
                      { required: true, message: "Please select one country!" },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Choose a country"
                      filterOption={(input, option) =>
                        option.props.children
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }
                    >
                      {renderCountries()}
                    </Select>
                  </Form.Item>

                  <Button
                    htmlType="submit"
                    size="default"
                    type="primary"
                    disabled={submitLoading}
                    loading={submitLoading}
                    key="submit"
                  >
                    {t("custom-offices.modal.add", "Add New Custom Office")}
                  </Button>
                </Form>
              </BasicFormWrapper>
            </AddProfile>
          </div>
        </Modal>
        <Modal
          type={state.modalType}
          title={t("custom-offices.modal.edit", "Edit Custom Office")}
          visible={state.editVisible}
          footer={null}
          onCancel={handleCancel}
        >
          <div className="project-modal">
            <AddProfile>
              <BasicFormWrapper>
                <Form
                  form={form}
                  name="customOfficeEdit"
                  onFinish={handleEditSubmit}
                >
                  <Form.Item label="Id" name="id" hidden>
                    <Input placeholder="Id" />
                  </Form.Item>

                  <Form.Item label={t("global.name", "Name")} name="name">
                    <Input placeholder="Name" type="text" />
                  </Form.Item>

                  <Form.Item
                    label={t("global.address", "Address")}
                    name="address"
                  >
                    <Input placeholder="Address" type="text" />
                  </Form.Item>

                  <Form.Item
                    label={t("global.zipCode", "ZipCode")}
                    name="zipCode"
                  >
                    <Input placeholder="ZipCode" type="text" />
                  </Form.Item>

                  <Form.Item
                    label={t("global.countryId", "Country")}
                    name="countryId"
                    rules={[
                      { required: true, message: "Please select one country!" },
                    ]}
                  >
                    <Select
                      value={form.getFieldsValue("countryId")}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }
                    >
                      {renderCountries()}
                    </Select>
                  </Form.Item>

                  <Button
                    htmlType="submit"
                    size="default"
                    type="primary"
                    disabled={submitLoading}
                    loading={submitLoading}
                    key="submit"
                  >
                    {t("custom-offices.editmodal.edit", "Edit Custom Office")}
                  </Button>
                </Form>
              </BasicFormWrapper>
            </AddProfile>
          </div>
        </Modal>
      </Main>
    </>
  );
};

export default CustomOffices;
