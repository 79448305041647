import { Col, Row, Skeleton } from "antd";
import { Suspense, useEffect, useState } from "react";

import { UsersApi } from "api/api";
import { Cards } from "components/cards/frame/cards-frame";
import { PageHeader } from "components/page-headers/page-headers";
import { Main } from "container/styled";
import SetNewPasswordPage from "pages/authentication/SetNewPasswordPage";
import EditProfile from "pages/profile/EditProfile";
import propTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AuthorBox, { BoxTypes } from "./overview/ProfileAuthorBox";
import CompanyProfile from "./overview/CompanyProfile";
import { getRole } from "utility/decode-jwt";
import { PredefinedRoles } from "constants/constants";
import { isSimpleUser } from "utility/isCompanyUser";
import UserShipments from "components/user-profile/UserShipments";

const userApi = new UsersApi();

function Settings() {
  const { t } = useTranslation();
  const [typeOfBox, setTypeOfBox] = useState(BoxTypes.profile);
  const [id, setId] = useState();
  const roles = getRole();
  const isAdminRole = Array.isArray(roles)
    ? roles.includes(PredefinedRoles.SUPER_ADMINISTRATOR)
    : roles === PredefinedRoles.SUPER_ADMINISTRATOR;

  const isFreightForwarderRole = Array.isArray(roles)
    ? roles.includes(PredefinedRoles.FREIGHT_FORWARDER)
    : roles === PredefinedRoles.FREIGHT_FORWARDER;
  const handleAuthorBox = (value) => {
    setTypeOfBox(value);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const {
        data: { roleName, id },
      } = await userApi.usersMeGet();
      const isCertifiedTechician = roleName?.includes(
        "Service technicians - individuals"
      );
      setId(id);
    } catch (err) {}
  };

  const renderBoxComponent = () => {
    switch (typeOfBox) {
      case BoxTypes.profile:
        return <EditProfile />;
      case BoxTypes.setPassword:
        return <SetNewPasswordPage isOnBoardingMode={false} />;
      case BoxTypes.company:
        return <CompanyProfile />;
      case BoxTypes.shipments:
        return (
          <div style={{ width: '100%' }}>
            <UserShipments />
          </div>
        );
      default:
        return null;
    }
  };

  const getCardTitle = () => {
    const { profile, setPassword, company, shipments } = BoxTypes;

    const titles = {
      [profile]: t("edit-profile:subtitle", "Set Up Your Personal Information"),
      [setPassword]: t("password:subtitle", "Change Password"),
      [company]: t("edit-company:subtitle", "Set Up Your Company Information"),
      [shipments]: t("shipments:subtitle", "My Shipments"),
    };

    return titles[typeOfBox];
  };

  const userProfileLabel = [
    {
      title: t("common:edit-profile", "Edit Profile"),
      icon: "user",
      label: "profile",
    },
    {
      title: t("common:change-password", "Change Password"),
      icon: "key",
      label: "setPassword",
    },
  ];

  const companyProfileLabel = [
    {
      title: t("common:edit-company", "Edit Company"),
      icon: "truck",
      label: "company",
    },
    {
      title: t("common:my-shipments", "My Shipments"),
      icon: "package",
      label: "shipments",
    }
  ];

  return (
    <>
      <PageHeader ghost title="Profile Settings" />

      <Main>
        <Row>
          <Col xxl={6} lg={8} md={24} xs={24}>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton avatar />
                </Cards>
              }
            >
              <AuthorBox
                onClick={handleAuthorBox}
                type={typeOfBox}
                labels={userProfileLabel}
              />
              {isSimpleUser() && (
                <div>
                  <h1 style={{ fontSize: 18, fontWeight: "600" }}>
                    Company Settings
                  </h1>

                  <AuthorBox
                    onClick={handleAuthorBox}
                    type={[BoxTypes.company, BoxTypes.shipments]}
                    labels={companyProfileLabel}
                  />
                </div>
              )}
            </Suspense>
          </Col>
          <Col xxl={18} lg={16} md={24} xs={24}>
            <Main>
              {typeOfBox === BoxTypes.shipments ? (
                <Cards
                  style={{ width: '100%' }}
                  title={getCardTitle()}
                >
                  {renderBoxComponent()}
                </Cards>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Cards
                    style={{ maxWidth: "100%", width: "100%" }}
                    title={getCardTitle()}
                  >
                    <Row justify="center" style={{ maxWidth: "100%" }}>
                      <div style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}>
                        {renderBoxComponent()}
                      </div>
                    </Row>
                  </Cards>
                </div>
              )}
            </Main>
          </Col>
        </Row>
      </Main>
    </>
  );
}

Settings.propTypes = {
  match: propTypes.object,
};

export default Settings;
