import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Row, Spin } from "antd";
import { DealsApi } from "api/api";
import { useEffect, useState } from "react";
import { Main } from "container/styled";
import { useTranslation } from "react-i18next";
import moment from "moment";
import FeatherIcon from "feather-icons-react";
import Bids from "components/bid/Bids";
import { DealDto } from "api/models";
import { useCompanyId } from "utility/useCompanyId";

// import BidComponent from "components/bid/BidComponent";

const dealsApi = new DealsApi();

function ViewDetails() {
  const params = useParams<any>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [deals, setDeals] = useState<DealDto[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const userCompanyId = useCompanyId();
  const viewDealId = params.id;

  const canViewSensitiveDetails = (deal: DealDto) => {
    if (deal.createdByCompanyId === userCompanyId) return true;

    // Check if user's company has a confirmed bid
    if (deal.bids && deal.bids.length > 0) return true;

    return false;
  };

  useEffect(() => {
    const fetchDealsId = async () => {
      if (viewDealId) {
        try {
          setIsLoading(true);
          const response: any = await dealsApi.apiDealsIdGet({
            id: viewDealId,
          });
          setDeals([response.data]);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchDealsId();
  }, [viewDealId]);

  const handleCloseClick = () => {
    navigate(-1);
  };

  //   const handleApplyClick = () => {
  //     message.success(`This is for the future (mobile app)!`);
  //   };

  return (
    <Main>
      <Row justify="center">
        <Col xs={20}>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
              }}
            >
              <Spin style={{ marginTop: -200 }} />
            </div>
          ) : (
            deals.map((deal) => (
              <Card
                title="View Deal Details"
                extra={
                  <Button type="link" onClick={handleCloseClick}>
                    <FeatherIcon icon="x" />
                  </Button>
                }
                key={deal.id}
                style={{ marginBottom: "30px", marginTop: "30px" }}
              >
                <Row gutter={[0, 10]}>
                  <Col span={20}></Col>
                  <Col span={4} style={{ textAlign: "right" }}>
                    {deal.controlledTemperature && (
                      <img
                        style={{
                          width: "28px",
                          height: "28px",
                          marginRight: "10px",
                        }}
                        src={require("../../static/img/icon/Cold_Truck_Icon.webp")}
                        alt=""
                      />
                    )}
                    {deal.isADR && (
                      <img
                        style={{ width: "28px", height: "28px" }}
                        src={require("../../static/img/icon/adr.png")}
                        alt=""
                      />
                    )}
                  </Col>
                </Row>
                <Row gutter={[0, 10]}>
                  <Col span={8} style={{ marginBottom: 10 }}>
                    <strong style={{ fontSize: "24px" }}>
                      {t(
                        "deals-list.deal-Basic-Information",
                        "Basic Information"
                      )}
                    </strong>
                  </Col>
                  <Col span={4}>
                    <span></span>
                  </Col>
                </Row>
                <Row gutter={[0, 10]}>
                  <Col span={8}>
                    <strong>{t("deals-list.commodity", "Commodity")}</strong>
                  </Col>
                  <Col span={12}>
                    <span>{deal.commodity}</span>
                  </Col>
                  <Col span={4}>
                    <span></span>
                  </Col>
                </Row>
                <Row gutter={[0, 10]}>
                  <Col span={8}>
                    <strong>{t("deals-list.hsCodes", "HS Codes")}</strong>
                  </Col>
                  <Col span={12}>
                    <span>{deal.hsCodeName}</span>
                  </Col>
                  <Col span={4}>
                    <span></span>
                  </Col>
                </Row>
                <Row gutter={[0, 10]}>
                  <Col span={8}>
                    <strong>{t("deals-list:volume", "Volume")}</strong>
                  </Col>
                  <Col span={12}>
                    <span>
                      {deal.volume} {t("deals-list.m³", "m³")}
                    </span>
                  </Col>
                  <Col span={4}>
                    <span></span>
                  </Col>
                </Row>
                <Row gutter={[0, 10]}>
                  {canViewSensitiveDetails(deal) && (
                    <>
                      <Col span={8}>
                        <strong>{t("deals-list:shipper", "Shipper")}</strong>
                      </Col>
                      <Col span={12}>
                        <span>{deal.shipper?.businessName}</span>
                      </Col>
                      <Col span={4}>
                        <span></span>
                      </Col>
                    </>
                  )}
                </Row>
                <Row gutter={[0, 10]}>
                  {canViewSensitiveDetails(deal) && (
                    <>
                      <Col span={8}>
                        <strong>{t("deals-list:consignee", "Consignee")}</strong>
                      </Col>
                      <Col span={12}>
                        <span>{deal.consignee?.businessName}</span>
                      </Col>
                      <Col span={4}>
                        <span></span>
                      </Col>
                    </>
                  )}
                </Row>

                <div style={{ paddingBottom: 15 }}>
                  <Row gutter={[0, 10]}>
                    <Col span={8}>
                      <strong>
                        {t(
                          "deals-list:special-requirements",
                          "Special Requirements "
                        )}
                      </strong>
                    </Col>
                    <Col span={12}>
                      <span>{deal.specialRequirements}</span>
                    </Col>
                    <Col span={4}>
                      <span></span>
                    </Col>
                  </Row>
                  <Row gutter={[0, 10]}>
                    <Col span={8}>
                      <strong>
                        {t("deals-list:dedicated-Truck", "Dedicated Truck ")}
                      </strong>
                    </Col>
                    <Col span={12}>
                      <span>
                        {deal.dedicatedTruck
                          ? t("deals-list:yes", "Yes")
                          : t("deals-list:no", "No")}
                      </span>
                    </Col>
                  </Row>
                  <Row gutter={[0, 10]}>
                    <Col span={8}>
                      <strong>{t("deals-list:length", "Length")}</strong>
                    </Col>
                    <Col span={12}>
                      <span>{deal.length} cm</span>
                    </Col>
                    <Col span={4}>
                      <span></span>
                    </Col>
                  </Row>
                  <Row gutter={[0, 10]}>
                    <Col span={8}>
                      <strong>{t("deals-list:height", "Height")}</strong>
                    </Col>
                    <Col span={12}>
                      <span>{deal.height} cm</span>
                    </Col>
                    <Col span={4}>
                      <span></span>
                    </Col>
                  </Row>
                  <Row gutter={[0, 10]}>
                    <Col span={8}>
                      <strong>{t("deals-list:width", "Width")}</strong>
                    </Col>
                    <Col span={12}>
                      <span>{deal.width} cm</span>
                    </Col>
                    <Col span={4}>
                      <span></span>
                    </Col>
                  </Row>
                  <Row gutter={[0, 10]}>
                    <Col span={8}>
                      <strong>{t("deals-list:weight", "Weight")}</strong>
                    </Col>
                    <Col span={12}>
                      <span>{deal.weight} kg</span>
                    </Col>
                    <Col span={4}>
                      <span></span>
                    </Col>
                  </Row>
                  <Row gutter={[0, 10]}>
                    <Col span={8}>
                      <strong>
                        {t(
                          "deals-list:controlled-Temperature",
                          "Controlled Temperature "
                        )}
                      </strong>
                    </Col>
                    <Col span={12}>
                      <span>
                        {deal.controlledTemperature
                          ? t("deals-list:yes", "Yes")
                          : t("deals-list:no", "No")}
                      </span>
                    </Col>
                  </Row>
                  <Row gutter={[0, 10]}>
                    <Col span={8}>
                      <strong>{t("deals-list:adr", "ADR ")}</strong>
                    </Col>
                    <Col span={12}>
                      <span>
                        {deal.isADR
                          ? t("deals-list:yes", "Yes")
                          : t("deals-list:no", "No")}
                      </span>
                    </Col>
                  </Row>
                  <Row gutter={[0, 10]}>
                    <Col span={8} style={{ marginTop: 10, marginBottom: 10 }}>
                      <strong style={{ fontSize: "24px" }}>
                        {t(
                          "deals-list.deal-pick-drop-location",
                          "Pick and Drop Location"
                        )}
                      </strong>
                    </Col>
                    <Col span={4}>
                      <span></span>
                    </Col>
                  </Row>
                  <Row gutter={[0, 10]}>
                    <Col span={8}>
                      <strong>
                        {t("deals-list.deal-pick-Location", "Pick Location")}
                      </strong>
                    </Col>
                    <Col span={12}>
                      <span>{deal.pickLocation}</span>
                    </Col>
                    <Col span={4}>
                      <span></span>
                    </Col>
                  </Row>
                  <Row gutter={[0, 10]}>
                    <Col span={8}>
                      <strong>
                        {t("deals-list.deal-drop-Location", "Drop Location")}
                      </strong>
                    </Col>
                    <Col span={12}>
                      <span>{deal.dropLocation}</span>
                    </Col>
                    <Col span={4}>
                      <span></span>
                    </Col>
                  </Row>
                  <Row gutter={[0, 10]}>
                    <Col span={8} style={{ marginTop: 10, marginBottom: 10 }}>
                      <strong style={{ fontSize: "24px" }}>
                        {t(
                          "deals-list.deal-shipping-information",
                          "Shipping Information"
                        )}
                      </strong>
                    </Col>
                    <Col span={4}>
                      <span></span>
                    </Col>
                  </Row>
                  <Row gutter={[0, 10]}>
                    {canViewSensitiveDetails(deal) && (
                      <>
                        <Col span={8}>
                          <strong>
                            {t(
                              "deals-list.deal-shipper-Terminal",
                              "Shipper Terminal"
                            )}
                          </strong>
                        </Col>
                        <Col span={12}>
                          <span>{deal.shipperTerminal?.name}</span>
                        </Col>
                        <Col span={4}>
                          <span></span>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row gutter={[0, 10]}>
                    {canViewSensitiveDetails(deal) && (
                      <>
                        <Col span={8}>
                          <strong>
                            {t(
                              "deals-list.deal-consignee-Terminal",
                              "Consignee Terminal"
                            )}
                          </strong>
                        </Col>
                        <Col span={12}>
                          <span>{deal.consigneeTerminal?.name}</span>
                        </Col>
                        <Col span={4}>
                          <span></span>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row gutter={[0, 10]}>
                    <Col span={8}>
                      <strong>
                        {t("deals-list:shipping-window", "Shipping Window ")}
                      </strong>
                    </Col>
                    <Col span={12}>
                      <span>
                        {`from ${moment(deal.shippingStartsAt).format(
                          "DD-MM-YYYY"
                        )} to ${moment(deal.shippingEndsAt).format(
                          "DD-MM-YYYY"
                        )}`}
                      </span>
                    </Col>
                    <Col span={4}>
                      <span></span>
                    </Col>
                  </Row>
                  <Row gutter={[0, 10]}>
                    <Col span={8}>
                      <strong>
                        {t("deals-list:cargo-date", "Cargo Ready Date")}
                      </strong>
                    </Col>
                    <Col span={12}>
                      <span>
                        {moment(deal.cargoReadyDate).format("DD-MM-YYYY")}
                      </span>
                    </Col>
                    <Col span={4}>
                      <span></span>
                    </Col>
                  </Row>
                  <Row gutter={[0, 10]}>
                    <Col span={8}>
                      <strong>
                        {t("deals-list:created-on", "Created on")}
                      </strong>
                    </Col>
                    <Col span={12}>
                      <span>{moment(deal.createdAt).format("DD-MM-YYYY")}</span>
                    </Col>
                    <Col span={4}>
                      <span></span>
                    </Col>
                  </Row>
                  <Col span={24}>
                    {deal.corridors && deal.corridors.length > 0 && (
                      <div style={{ marginTop: 15, paddingBottom: 10 }}>
                        <strong>{`Corridors (${deal.corridors.length}):`}</strong>
                      </div>
                    )}
                  </Col>
                  {deal.corridors?.map((corridor, index) => (
                    <div key={index}>
                      <Row gutter={[0, 10]}>
                        <Col span={12}>
                          <FeatherIcon icon="map-pin" />
                          <span style={{ marginLeft: "10px" }}>
                            {corridor.description}
                          </span>
                        </Col>
                        <Col span={4}>
                          <span></span>
                        </Col>
                      </Row>

                      <Row gutter={[0, 10]}>
                        {/* <Col span={8}>
                          <div style={{ paddingLeft: 10 }}>|</div>
                        </Col> */}
                      </Row>
                      <Row gutter={[0, 10]}>
                        <Col span={12}>
                          <span style={{ marginLeft: "36px" }}>
                            {corridor.specialRequirements}
                          </span>
                        </Col>
                        <Col span={4}>
                          <span></span>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
                <Bids
                  dealId={viewDealId}
                  // controlledTemperature={deal.controlledTemperature}
                  // allowsADR={deal.isADR}
                  // location={deal.pickLocation}
                />
              </Card>
            ))
          )}
        </Col>
      </Row>
    </Main>
  );
}

export default ViewDetails;
