import { PredefinedRoles } from "constants/constants";
import { decodeJWT, getRole } from "utility/decode-jwt";

const isCompanyUser = () => {
  const role = getRole();
  return role?.includes(PredefinedRoles.TRANSPORTER) && !role?.includes("SuperAdministrator");
};

const isSimpleUser = () => {
  // const token = decodeJWT() as any;
  // return token ? token?.Permissions?.includes("dashboard:view") : "";

  const role = getRole();
  return role?.includes(PredefinedRoles.USER)
};

const isAdminUser = () => {
  const role = getRole();
  return role?.includes("SuperAdministrator");
}

export { isSimpleUser, isCompanyUser, isAdminUser };
