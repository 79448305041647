import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { message, Table } from "antd";
import { TableWrapper } from "container/styled";
import { useTranslation } from "react-i18next";
import { DealStatusEnum } from "api/models";
import { useModalState } from "hooks/useModalState";
import { useBidsFetch } from "components/bid/hooks/useBidsFetch";
import { DealsApi } from "api/api";
import { useWorkbookColumns } from "./workbookDataColumns";
import Bid from "components/bid/Bid";

const dealsApi = new DealsApi();

const WorkbookTable = ({ filterType }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [deals, setDeals] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentDealId, setCurrentDealId] = useState<any>(null);
  const [query, setQuery] = useState({
    status: filterType || DealStatusEnum.NUMBER_2,
    pageNumber: 1,
    pageSize: 10,
    search: "",
    sortBy: "",
    sortType: "",
  });
  const { fetchBids } = useBidsFetch({ currentDealId });
  const { modalState, closeModal, handleAddClick } = useModalState(fetchBids);

  const fetchDeals = useCallback(async () => {
    try {
      setIsLoading(true);
      const response: any = await dealsApi.apiDealsGet({
        ...query,
        status: filterType || DealStatusEnum.NUMBER_2,
      });
      setDeals(response.data);
    } catch (error) {
      console.error("Error fetching deals:", error);
    } finally {
      setIsLoading(false);
    }
  }, [query, filterType]);

  useEffect(() => {
    if (query) {
      fetchDeals();
    }
  }, [fetchDeals, query]);

  const handleViewClick = (id: string) => {
    navigate(`/deal/${id}`);
  };
  const handleEditClick = (id: string) => {
    navigate(`/deal/${id}/edit`);
  };

  const handlePlaceBidClick = (dealId: string) => {
    setCurrentDealId(dealId);
    handleAddClick();
  };

  const columns = useWorkbookColumns({
    handlePlaceBidClick,
    handleViewClick,
    handleEditClick,
    filterType,
  });

  const handlePaginationChange = (pageNumber: number) => {
    setQuery((prevQuery) => ({ ...prevQuery, pageNumber }));
  };

  const handleShowSizeChange = (pageNumber: number, pageSize: number) => {
    setQuery((prevQuery) => ({ ...prevQuery, pageNumber, pageSize }));
  };

  return (
    <>
      <TableWrapper className="table-responsive">
        <Table
          loading={isLoading}
          dataSource={deals?.items}
          columns={columns}
          showSorterTooltip={false}
          pagination={{
            current: deals?.pageIndex,
            total: deals?.totalCount,
            showSizeChanger: true,
            pageSizeOptions: [10, 50, 100, 1000],
            pageSize: query.pageSize,
            onChange: handlePaginationChange,
            onShowSizeChange: handleShowSizeChange,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </TableWrapper>
      <Bid
        isVisible={modalState.addModalVisible}
        onHide={closeModal}
        mode={modalState.mode as any}
        bidToEdit={modalState.itemForEditModal}
        onSave={() => {
          fetchDeals();
          fetchBids();
        }}
        t={t}
        dealId={currentDealId}
      />
    </>
  );
};

export default WorkbookTable;
