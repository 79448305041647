import { Button } from "components/buttons/buttons";
import { PageHeader } from "components/page-headers/page-headers";
import { CardToolbox } from "container/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";
import DealsList from "components/deals/DealsList";
import { isSimpleUser } from "utility/isCompanyUser";

function NewsFeed() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleAddClick = () => {
    navigate("/deal/initiate");
  };

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t("deals:news", "NewsFeed")}
          buttons={[
            // isFreightForwarder && (
            isSimpleUser() && (
              <Button key="add-button" onClick={handleAddClick}>
                <FeatherIcon icon="plus" size={25}></FeatherIcon>
                {t("deals.button.add", "Add new")}
              </Button>
            ),
          ]}
        />
      </CardToolbox>
      <DealsList />
    </>
  );
}

export default NewsFeed;
