import { Col, Popconfirm, Row, Table } from "antd";
import { CountryDto } from "api/models";
import { Button } from "components/buttons/buttons";
import { Cards } from "components/cards/frame/cards-frame";
import { PageHeader } from "components/page-headers/page-headers";
import {
  CardToolbox,
  Main,
  ProfilePageHeaderStyle,
  ProfileTableStyleWrapper,
  TableWrapper,
} from "container/styled";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { hasPermission } from "utility/accessibility/hasPermission";
//@ts-ignore
import FeatherIcon from "feather-icons-react";
import openNotificationWithIcon from "utility/notification";
import { ColumnsType } from "antd/lib/table";
import { sortDirections } from "constants/constants";
import { ExportButtonPageHeader } from "components/buttons/export-button/export-button";
import { CountriesApi } from "api/api";
// import { CountriesModal } from "./CountriesModal";
import { AutoComplete } from "components/autoComplete/autoComplete";
import { isSimpleUser } from "utility/isCompanyUser";

const countryApi = new CountriesApi();

type StateType = {
  addModalVisible?: boolean;
  itemForEditModal?: CountryDto | null;
};

export const CountriesPage = () => {
  const { t } = useTranslation();
  const [countriesData, setCountriesData] = useState<CountryDto[]>([]);
  const [loading, setLoading] = useState(false);
  // const [modalsState, setModalsState] = useState<StateType>({
  //   addModalVisible: false,
  //   itemForEditModal: null,
  // });

  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 10,
    search: "",
  });
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    fetchCountries();
  }, [query]);

  const fetchCountries = async () => {
    try {
      setLoading(true);
      const response = await countryApi.apiCountriesGet({
        pageNumber: query.pageNumber,
        pageSize: query.pageSize,
        search: query.search,
      });

      setCountriesData(response.data.items ?? []);
      setTotalCount(response.data.totalCount ?? 0);
    } catch (error) {
      console.error("Error fetching countries:", error);
      openNotificationWithIcon("error", t("global:error", "Error occurred"));
    } finally {
      setLoading(false);
    }
  };

  // const handleAddClick = () => {
  //   setModalsState({
  //     addModalVisible: true,
  //     itemForEditModal: null,
  //   });
  // };

  // const handleEditClick = (item: CountryDto) => {
  //   setModalsState({
  //     itemForEditModal: item,
  //   });
  // };

  // const handleSuccessAddOrEdit = async () => {
  //   closeModal();
  //   await fetchCountries();
  // };

  // const closeModal = () => {
  //   setModalsState({
  //     addModalVisible: false,
  //     itemForEditModal: null,
  //   });
  // };

  // const handleDeleteConfirm = async (id: string | undefined) => {
  //   try {
  //     if (!id) return;
  //     await countryApi.apiCountriesIdDelete({ id });
  //     openNotificationWithIcon(
  //       "success",
  //       t("countries:success-deleted", "Country deleted successfully!")
  //     );
  //     await fetchCountries();
  //   } catch (err) {
  //     openNotificationWithIcon("error", t("global:error", "Error occurred"));
  //   }
  // };

  const handleSearchChange = (value: string) => {
    setQuery((prevQuery) => ({ ...prevQuery, search: value }));
  };

  const columns: ColumnsType<any> = [
    {
      title: t("global.name", "Name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortDirections,
    },
    {
      title: t("global.flag", "Flag"),
      dataIndex: "flag",
      key: "flag",
      align: "left",
      render: (flag: string) =>
        flag ? (
          <img
            src={flag}
            alt="flag"
            style={{ width: "30px", height: "20px", objectFit: "cover" }}
          />
        ) : (
          <span>-</span>
        ),
    },
    // {
    //   title: t("global.actions", "Actions"),
    //   dataIndex: "action",
    //   key: "action",
    //   width: "90px",
    // },
  ];

  const tableData = (countriesData ?? []).map((item) => {
    const { id, name, flag } = item;
    return {
      key: id,
      name,
      flag,
      // action: (
      //   <div key={id} className="table-actions">
      //     {isSimpleUser() && (
      //       <Button className="btn-icon" type="primary" to="#" shape="circle">
      //         <Link onClick={() => handleEditClick(item)} to="#">
      //           <FeatherIcon icon="edit" size={16} />
      //         </Link>
      //       </Button>
      //     )}
      //     {isSimpleUser() ? (
      //       <Popconfirm
      //         title={t(
      //           "countries:delete-confirmation",
      //           "Please confirm if you want to permanently delete this country"
      //         )}
      //         onConfirm={() => handleDeleteConfirm(id)}
      //         okText="Yes"
      //         cancelText="No"
      //       >
      //         <Button className="btn-icon" type="danger" to="#" shape="circle">
      //           <FeatherIcon icon="trash-2" size={16} />
      //         </Button>
      //       </Popconfirm>
      //     ) : null}
      //   </div>
      // ),
    };
  });

  const handlePaginationChange = (pageNumber: number) => {
    setQuery((prevQuery) => ({ ...prevQuery, pageNumber }));
  };

  const handleShowSizeChange = (pageNumber: number, pageSize: number) => {
    setQuery((prevQuery) => ({ ...prevQuery, pageNumber, pageSize }));
  };

  return (
    <>
      <CardToolbox>
        <ProfilePageHeaderStyle>
          <PageHeader
            ghost
            title={t("countries:title", "Countries")}
            buttons={[
              <ExportButtonPageHeader
                key="1"
                title={t("countries:title", "Countries")}
                data={tableData}
                columns={["name"]}
              />,
              // isSimpleUser() && (
              //   <Button
              //     //onClick={handleAddClick}
              //     className="btn-add_new"
              //     size="default"
              //     type="primary"
              //     key="add-country"
              //   >
              //     {t("countries:add", "Add new Country")}
              //   </Button>
              // ),
            ]}
          />
          <div
            className="table-search-box"
            style={{
              width: "200px",
              marginBottom: "16px",
            }}
          >
            <AutoComplete onSearch={handleSearchChange} width="100%" patterns />
          </div>
        </ProfilePageHeaderStyle>
      </CardToolbox>
      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <ProfileTableStyleWrapper>
                <div className="contact-table">
                  <TableWrapper className="table-responsive">
                    <Table
                      loading={loading}
                      dataSource={tableData}
                      columns={columns}
                      showSorterTooltip={false}
                      rowKey="key"
                      pagination={{
                        current: query.pageNumber,
                        total: totalCount,
                        showSizeChanger: true,
                        pageSizeOptions: [10, 50, 100, 1000],
                        pageSize: query.pageSize,
                        onChange: handlePaginationChange,
                        onShowSizeChange: handleShowSizeChange,
                        showTotal: (total, range) =>
                          `${range[0]}-${range[1]} of ${total} items`,
                      }}
                    />
                  </TableWrapper>
                </div>
              </ProfileTableStyleWrapper>
            </Cards>
          </Col>
        </Row>
      </Main>

      {/* <CountriesModal
        isVisible={Boolean(
          modalsState.addModalVisible || modalsState.itemForEditModal
        )}
        onHide={closeModal}
        onSuccess={handleSuccessAddOrEdit}
        countryToEdit={modalsState.itemForEditModal}
      /> */}
    </>
  );
};
