import { useTranslation } from "react-i18next";
import FeatherIcon from "feather-icons-react";
import { ColumnsType } from "antd/lib/table";

import { Button } from "components/buttons/buttons";
import { Popconfirm } from "antd";
import { hasPermission } from "utility/accessibility/hasPermission";
import { CompanyStatus, CompanyTypeEnum } from "api/models";
import { Tag } from "components/tags/tags";

export interface CompaniesModel {
  id: string;
  businessName: string;
  tradeName: string;
  businessNumber: string;
  fiscalNumber: string;
  address: string;
  phoneNumber: string;
  companyEmail: string;
  canEditCompany: boolean;
  fileList?: File[];
  status?: CompanyStatus;
}

export const CompanyStatusDescriptions: { [key in CompanyStatus]: string } = {
  [CompanyStatus.NUMBER_1]: "Verified",
  [CompanyStatus.NUMBER_2]: "Un Verified",
  [CompanyStatus.NUMBER_3]: "Deleted",
  [CompanyStatus.NUMBER_4]: "All",
};

interface ActionHandlers {
  handleViewClick: (record: CompaniesModel) => void;
  handleViewDashboardClick: (record: CompaniesModel) => void;
  handleEditClick: (record: CompaniesModel) => void;
  handleDeleteClick: (record: CompaniesModel) => void;
}

const getPermissionAction = (companyType: any, action: any) => {
  switch (companyType) {
    case CompanyTypeEnum.NUMBER_0:
      return `transporters:${action}`;
    case CompanyTypeEnum.NUMBER_1:
      return `clients:${action}`;
    case CompanyTypeEnum.NUMBER_2:
      return `freight-forwarders:${action}`;
    case CompanyTypeEnum.NUMBER_3:
      return `shippers:${action}`;
    default:
      return `users:${action}`;
  }
};

export const useCompaniesColumns = ({
  handleViewClick,
  handleViewDashboardClick,
  handleEditClick,
  handleDeleteClick,
  companyType,
}: ActionHandlers & {
  companyType: CompanyTypeEnum;
}): ColumnsType<CompaniesModel> => {
  const { t } = useTranslation();

  return [
    {
      title: t("company:input.businessName", "Business Name"),
      dataIndex: "businessName",
      key: "businessName",
    },
    {
      title: t("company:input.tradeName", "Trade Name"),
      dataIndex: "tradeName",
      key: "tradeName",
    },
    {
      title: t("company:input.businessNumber", "Business Number"),
      dataIndex: "businessNumber",
      key: "businessNumber",
    },
    {
      title: t("company:input.fiscalNumber", "Fiscal Number"),
      dataIndex: "fiscalNumber",
      key: "fiscalNumber",
    },
    {
      title: t("company:input.address", "Address"),
      dataIndex: "address",
      key: "address",
    },
    {
      title: t("company:input.phoneNumber", "Phone Number"),
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: t("company:input.companyEmail", "Company Email"),
      dataIndex: "companyEmail",
      key: "companyEmail",
    },
    {
      title: t("company:input.status", "Status"),
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <Tag
          color={record.status === CompanyStatus.NUMBER_1 ? "success" : "error"}
        >
          {CompanyStatusDescriptions[record.status || CompanyStatus.NUMBER_1]}
        </Tag>
      ),
    },
    {
      title: t("company:table.action", "Action"),
      key: "action",
      render: (_, record) => (
        <div className="table-actions" style={{ clear: "both" }}>
          {companyType === CompanyTypeEnum.NUMBER_0 && (
            <Button
              className="btn-icon"
              type="info"
              shape="circle"
              onClick={() => handleViewDashboardClick(record)}
            >
              <FeatherIcon icon="pie-chart" size={25} />
            </Button>
          )}
          <Button
            className="btn-icon"
            type="info"
            shape="circle"
            onClick={() => handleViewClick(record)}
          >
            <FeatherIcon icon="eye" size={25} />
          </Button>
          {hasPermission(getPermissionAction(companyType, "edit")) &&
            record.canEditCompany && (
              <Button
                className="btn-icon"
                type="info"
                shape="circle"
                onClick={() => handleEditClick(record)}
              >
                <FeatherIcon icon="edit" size={25} />
              </Button>
            )}
          {hasPermission(getPermissionAction(companyType, "delete")) && (
            <Popconfirm
              title={t(
                "company:alert.delete-confirm",
                "This step is irreversible, are you sure you want to delete {{dynamicValue}}?",
                {
                  dynamicValue: `${record.businessName} - ${record.businessNumber}`,
                }
              )}
              onConfirm={() => handleDeleteClick(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button className="btn-icon" type="info" shape="circle">
                <FeatherIcon icon="trash" size={25} />
              </Button>
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];
};
