import React from "react";
import { Modal, Button, Row, Col } from "antd";
import { BidType } from "./bidDataTableColumns";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

interface BidPreviewModalProps {
  visible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  bidDetails: BidType | null;
  loading: boolean;
}

const BidPreviewModal: React.FC<BidPreviewModalProps> = ({
  visible,
  onCancel,
  onConfirm,
  bidDetails,
  loading,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      title="Confirm Bid"
      open={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          onClick={onConfirm}
          loading={loading}
        >
          Confirm
        </Button>,
      ]}
    >
      {bidDetails ? (
        <div>
          <Row gutter={[0, 10]}>
            <Col span={8}>
              <strong>{t("deals-list:transporter", "Transporter")}</strong>
            </Col>
            <Col span={12}>
              <span>{bidDetails.transporterName}</span>
            </Col>
            <Col span={4}>
              <span></span>
            </Col>
          </Row>
          <Row gutter={[0, 10]}>
            <Col span={8}>
              <strong>{t("deals-list:driver", "Driver")}</strong>
            </Col>
            <Col span={12}>
              <span>{bidDetails.driverName}</span>
            </Col>
            <Col span={4}>
              <span></span>
            </Col>
          </Row>
          <Row gutter={[0, 10]}>
            <Col span={8}>
              <strong>{t("deals-list:equipment", "Equipment")}</strong>
            </Col>
            <Col span={12}>
              <span>{bidDetails.equipmentName}</span>
            </Col>
            <Col span={4}>
              <span></span>
            </Col>
          </Row>
          <Row gutter={[0, 10]}>
            <Col span={8}>
              <strong>{t("deals-list:cost", "Cost")}</strong>
            </Col>
            <Col span={12}>
              <span>{bidDetails.cost} €</span>
            </Col>
            <Col span={4}>
              <span></span>
            </Col>
          </Row>
          <Row gutter={[0, 10]}>
            <Col span={8}>
              <strong>{t("deals-list:parking-Fee", "Parking Fee")}</strong>
            </Col>
            <Col span={12}>
              <span>{bidDetails.parkingFee} €</span>
            </Col>
            <Col span={4}>
              <span></span>
            </Col>
          </Row>
          <Row gutter={[0, 10]}>
            <Col span={8}>
              <strong>{t("deals-list:price", "Price")}</strong>
            </Col>
            <Col span={12}>
              <span>{bidDetails.price} €</span>
            </Col>
            <Col span={4}>
              <span></span>
            </Col>
          </Row>
          <Row gutter={[0, 10]}>
            <Col span={8}>
              <strong>{t("deals-list:loading-date", "Loading Date")}</strong>
            </Col>
            <Col span={12}>
              <span>
                {bidDetails.loadingDate
                  ? format(new Date(bidDetails.loadingDate), "yyyy- MM-dd")
                  : "-"}
              </span>
            </Col>
            <Col span={4}>
              <span></span>
            </Col>
          </Row>
          {/* <Row gutter={[0, 10]}>
            <Col span={8}>
              <strong>{t("deals-list:status", "Status")}</strong>
            </Col>
            <Col span={12}>
              <span>{bidDetails.statusName}</span>
            </Col>
            <Col span={4}>
              <span></span>
            </Col>
          </Row> */}
        </div>
      ) : (
        <p>No bid details available</p>
      )}
    </Modal>
  );
};

export default BidPreviewModal;
