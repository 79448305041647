/* tslint:disable */
/* eslint-disable */
/**
 * Logisticx App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddDealCommand } from '../models';
// @ts-ignore
import { DealDto } from '../models';
// @ts-ignore
import { DealDtoOrdinalPaginatedList } from '../models';
// @ts-ignore
import { DealStatusEnum } from '../models';
// @ts-ignore
import { UpdateDealCommand } from '../models';
/**
 * DealsApi - axios parameter creator
 * @export
 */
export const DealsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {DealStatusEnum} [status] 
         * @param {boolean} [mineOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealsGet: async (pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, status?: DealStatusEnum, mineOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['SortBy'] = sortBy;
            }

            if (sortType !== undefined) {
                localVarQueryParameter['SortType'] = sortType;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (mineOnly !== undefined) {
                localVarQueryParameter['MineOnly'] = mineOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDealsIdDelete', 'id', id)
            const localVarPath = `/api/Deals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDealsIdGet', 'id', id)
            const localVarPath = `/api/Deals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddDealCommand} [addDealCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealsPost: async (addDealCommand?: AddDealCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDealCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDealCommand} [updateDealCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealsPut: async (updateDealCommand?: UpdateDealCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDealCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DealsApi - functional programming interface
 * @export
 */
export const DealsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DealsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {DealStatusEnum} [status] 
         * @param {boolean} [mineOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDealsGet(pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, status?: DealStatusEnum, mineOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealsGet(pageNumber, pageSize, sortBy, sortType, search, status, mineOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDealsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDealsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddDealCommand} [addDealCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDealsPost(addDealCommand?: AddDealCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealsPost(addDealCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDealCommand} [updateDealCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDealsPut(updateDealCommand?: UpdateDealCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealsPut(updateDealCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DealsApi - factory interface
 * @export
 */
export const DealsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DealsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {DealStatusEnum} [status] 
         * @param {boolean} [mineOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealsGet(pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, status?: DealStatusEnum, mineOnly?: boolean, options?: any): AxiosPromise<DealDtoOrdinalPaginatedList> {
            return localVarFp.apiDealsGet(pageNumber, pageSize, sortBy, sortType, search, status, mineOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiDealsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealsIdGet(id: string, options?: any): AxiosPromise<DealDto> {
            return localVarFp.apiDealsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddDealCommand} [addDealCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealsPost(addDealCommand?: AddDealCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiDealsPost(addDealCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDealCommand} [updateDealCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealsPut(updateDealCommand?: UpdateDealCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiDealsPut(updateDealCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDealsGet operation in DealsApi.
 * @export
 * @interface DealsApiApiDealsGetRequest
 */
export interface DealsApiApiDealsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DealsApiApiDealsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof DealsApiApiDealsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof DealsApiApiDealsGet
     */
    readonly sortBy?: string

    /**
     * 
     * @type {string}
     * @memberof DealsApiApiDealsGet
     */
    readonly sortType?: string

    /**
     * 
     * @type {string}
     * @memberof DealsApiApiDealsGet
     */
    readonly search?: string

    /**
     * 
     * @type {DealStatusEnum}
     * @memberof DealsApiApiDealsGet
     */
    readonly status?: DealStatusEnum

    /**
     * 
     * @type {boolean}
     * @memberof DealsApiApiDealsGet
     */
    readonly mineOnly?: boolean
}

/**
 * Request parameters for apiDealsIdDelete operation in DealsApi.
 * @export
 * @interface DealsApiApiDealsIdDeleteRequest
 */
export interface DealsApiApiDealsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DealsApiApiDealsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiDealsIdGet operation in DealsApi.
 * @export
 * @interface DealsApiApiDealsIdGetRequest
 */
export interface DealsApiApiDealsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DealsApiApiDealsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiDealsPost operation in DealsApi.
 * @export
 * @interface DealsApiApiDealsPostRequest
 */
export interface DealsApiApiDealsPostRequest {
    /**
     * 
     * @type {AddDealCommand}
     * @memberof DealsApiApiDealsPost
     */
    readonly addDealCommand?: AddDealCommand
}

/**
 * Request parameters for apiDealsPut operation in DealsApi.
 * @export
 * @interface DealsApiApiDealsPutRequest
 */
export interface DealsApiApiDealsPutRequest {
    /**
     * 
     * @type {UpdateDealCommand}
     * @memberof DealsApiApiDealsPut
     */
    readonly updateDealCommand?: UpdateDealCommand
}

/**
 * DealsApi - object-oriented interface
 * @export
 * @class DealsApi
 * @extends {BaseAPI}
 */
export class DealsApi extends BaseAPI {
    /**
     * 
     * @param {DealsApiApiDealsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApi
     */
    public apiDealsGet(requestParameters: DealsApiApiDealsGetRequest = {}, options?: AxiosRequestConfig) {
        return DealsApiFp(this.configuration).apiDealsGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.sortBy, requestParameters.sortType, requestParameters.search, requestParameters.status, requestParameters.mineOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealsApiApiDealsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApi
     */
    public apiDealsIdDelete(requestParameters: DealsApiApiDealsIdDeleteRequest, options?: AxiosRequestConfig) {
        return DealsApiFp(this.configuration).apiDealsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealsApiApiDealsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApi
     */
    public apiDealsIdGet(requestParameters: DealsApiApiDealsIdGetRequest, options?: AxiosRequestConfig) {
        return DealsApiFp(this.configuration).apiDealsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealsApiApiDealsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApi
     */
    public apiDealsPost(requestParameters: DealsApiApiDealsPostRequest = {}, options?: AxiosRequestConfig) {
        return DealsApiFp(this.configuration).apiDealsPost(requestParameters.addDealCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealsApiApiDealsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApi
     */
    public apiDealsPut(requestParameters: DealsApiApiDealsPutRequest = {}, options?: AxiosRequestConfig) {
        return DealsApiFp(this.configuration).apiDealsPut(requestParameters.updateDealCommand, options).then((request) => request(this.axios, this.basePath));
    }
}
