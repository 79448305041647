import { Button, Card, Col, Pagination, Radio, Row, Spin, message } from "antd";
import { BidsApi, DealsApi } from "api/api";
import { useCallback, useEffect, useState } from "react";
import { BasicFormWrapper, Main } from "container/styled";
import { ButtonGroup } from "components/datePicker/style";
import { useTranslation } from "react-i18next";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";
import { getRole } from "utility/decode-jwt";
import { PredefinedRoles } from "constants/constants";
import { useModalState } from "hooks/useModalState";
import Bid from "components/bid/Bid";
import { hasPermission } from "utility/accessibility/hasPermission";
import { DealDto, DealStatusEnum } from "api/models";
import { useBidsFetch } from "components/bid/hooks/useBidsFetch";
import styled from "styled-components";
import { isSimpleUser } from "utility/isCompanyUser";

const ConfirmedBadge = styled.span`
  padding: 3px 6px;
  border-radius: 10px;
  background: #c0dd77;
  color: #7aa809;
`;

const RadioButtonWrapper = styled(Radio.Button)`
  &.ant-radio-button-wrapper {
    padding: 0 8px 0 8px;
    line-height: 36px;
    height: 40px;
  }
`;

const dealsApi = new DealsApi();
const bidsApi = new BidsApi();

function generateDealHeadline(deal: DealDto): string {
  const { consignee, shipper, pickLocation, dropLocation } = deal;
  const typeOfTransport = deal.dedicatedTruck
    ? "Dedicated Truck"
    : "Regular Transport";
  return `Offer // ${pickLocation} - ${dropLocation} // ${typeOfTransport} // ${consignee?.businessName} // ${shipper?.businessName}`;
}

function DealsList() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [deals, setDeals] = useState<DealDto[]>([]);
  const [selectedTransporterName, setSelectedTransporterName] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [currentDealId, setCurrentDealId] = useState<any>(null);

  const [filterType, setFilterType] = useState<"all" | "mine">(() => {
    return (localStorage.getItem('newsFeedFilter') as "all" | "mine") || "all";
  });

  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 10,
    search: "",
    sortBy: "",
    sortType: "",
  });
  const [totalCount, setTotalCount] = useState(0);

  const { fetchBids } = useBidsFetch({ currentDealId });
  const { modalState, closeModal, handleAddClick } = useModalState(fetchBids);

  console.log(deals, "aaaaaaaaaa");

  const fetchDeals = useCallback(async () => {
    try {
      setIsLoading(true);
      const response: any = await dealsApi.apiDealsGet({
        ...query,
        ...(filterType === "all"
          ? { status: DealStatusEnum.NUMBER_3, mineOnly: false }
          : { status: undefined, mineOnly: true }),
      });
      const dealsData = response.data.items;

      dealsData.map(async (deal: DealDto) => {
        if (deal.selectedBidId) {
          const bidResponse = await bidsApi.apiBidsIdGet({
            id: deal.selectedBidId,
          });

          const transporter = bidResponse.data.transporterName;
          setSelectedTransporterName(transporter);
        }
      });
      setDeals(response.data.items);
      setTotalCount(response.data.totalCount);
    } catch (error) {
      message.error("Failed to fetch deals");
      console.error("Error fetching deals:", error);
    } finally {
      setIsLoading(false);
    }
  }, [query, filterType]);

  useEffect(() => {
    fetchDeals();
  }, [fetchDeals]);

  const handleEditClick = (id: string) => {
    navigate(`/deal/${id}/edit`);
  };

  const handleViewClick = (id: string) => {
    navigate(`/deal/${id}`);
  };

  const handlePlaceBidClick = (dealId: string) => {
    setCurrentDealId(dealId);
    handleAddClick();
  };

  const handleFilterChange = (e: any) => {
    const newFilter = e.target.value;
    setFilterType(newFilter);
    localStorage.setItem('newsFeedFilter', newFilter);
  };

  const renderFilter = () => {
    return (
      <div className="table-toolbox-menu" style={{ marginBottom: 16 }}>
        <Radio.Group
          value={filterType}
          onChange={handleFilterChange}
        >
          <RadioButtonWrapper value="all">
            {t("deals-list.filter.all", "All Quotes")}
          </RadioButtonWrapper>
          <RadioButtonWrapper value="mine">
            {t("deals-list.filter.mine", "My Quotes")}
          </RadioButtonWrapper>
        </Radio.Group>
      </div>
    );
  };

  return (
    <Main>
      <BasicFormWrapper>
        <Row justify="center">
          <Col xs={24} sm={20} md={20} lg={20}>
            {renderFilter()}
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "100vh",
                }}
              >
                <Spin style={{ marginTop: -200 }} />
              </div>
            ) : (
              <>
                {deals.map((deal) => (
                  <Card
                    key={deal.id}
                    title={generateDealHeadline(deal)}
                    style={{ marginBottom: "30px" }}
                    extra={
                      <ButtonGroup style={{ borderTop: "none" }}>
                        {/* {hasPermission("deals:list") && ( */}
                        {hasPermission("users:list") && (
                          <Button
                            type="link"
                            onClick={() => handleViewClick(deal.id!)}
                          >
                            View
                          </Button>
                        )}
                        {/* {deal.canEditDeal && deal.bids?.length === 0 && ( )} */}
                        <Button
                          type="link"
                          onClick={() => handleEditClick(deal.id!)}
                        >
                          Edit
                        </Button>
                      </ButtonGroup>
                    }
                  >
                    <Row gutter={[0, 10]}>
                      <Col span={4}>
                        <strong>
                          {t("deals-list.deal-commodity", "Commodity")}
                        </strong>
                      </Col>
                      <Col>{deal.commodity}</Col>
                      <Col span={4}>
                        <span></span>
                      </Col>
                    </Row>
                    <Row gutter={[0, 10]}>
                      <Col span={4}>
                        <strong>{t("deals-list.status", "Status")}</strong>
                      </Col>
                      <Col>
                        <span>
                          {deal.status === DealStatusEnum.NUMBER_2 ? (
                            <ConfirmedBadge>✓ CONFIRMED</ConfirmedBadge>
                          ) : (
                            <p>QUOTING</p>
                          )}
                        </span>
                      </Col>
                      <Col span={4}>
                        <span></span>
                      </Col>
                    </Row>
                    <Row gutter={[0, 10]}>
                      <Col span={4}>
                        {deal.status === DealStatusEnum.NUMBER_2 ? (
                          <strong>
                            {t("deals-list.transporter", "Transporter")}
                          </strong>
                        ) : (
                          <strong>
                            {t("deals-list.createdBy", "Created By")}
                          </strong>
                        )}
                      </Col>
                      <Col>
                        <span>
                          {deal.status === DealStatusEnum.NUMBER_2
                            ? selectedTransporterName
                            : deal.createdBy}
                        </span>
                      </Col>
                      <Col span={4}>
                        <span></span>
                      </Col>
                    </Row>
                    <ButtonGroup style={{ borderTop: "none" }}>
                      {/* {isFreightForwarderRole && */}
                      {isSimpleUser() &&
                      deal.status !== DealStatusEnum.NUMBER_2 ? (
                        <Button
                          type="primary"
                          className="btn-add_new"
                          key="add-codebook"
                          onClick={() => handlePlaceBidClick(deal.id!)}
                        >
                          {/* {isFreightForwarderRole */}
                          {isSimpleUser()
                            ? t("deals.place-bid", "Place a bid")
                            : t("deals.apply", "Apply")}
                        </Button>
                      ) : null}
                    </ButtonGroup>
                  </Card>
                ))}
                <Pagination
                  current={query.pageNumber}
                  total={totalCount}
                  showSizeChanger
                  pageSizeOptions={["10", "50", "100", "1000"]}
                  onChange={(pageNumber, pageSize) =>
                    setQuery((prev) => ({ ...prev, pageNumber, pageSize }))
                  }
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  style={{ textAlign: "center", marginTop: 20 }}
                />
              </>
            )}

            <Bid
              isVisible={modalState.addModalVisible}
              onHide={closeModal}
              mode={modalState.mode as any}
              bidToEdit={modalState.itemForEditModal}
              onSave={() => {
                fetchDeals();
                fetchBids();
              }}
              t={t}
              dealId={currentDealId}
            />
          </Col>
        </Row>
      </BasicFormWrapper>
    </Main>
  );
}

export default DealsList;
