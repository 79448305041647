/* tslint:disable */
/* eslint-disable */
/**
 * Logisticx App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BidDto } from '../models';
// @ts-ignore
import { BidDtoOrdinalPaginatedList } from '../models';
// @ts-ignore
import { ChangeBidStatusDto } from '../models';
// @ts-ignore
import { ConfirmBidCommand } from '../models';
/**
 * BidsApi - axios parameter creator
 * @export
 */
export const BidsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [controlledTemperature] 
         * @param {boolean} [allowsADR] 
         * @param {string} [location] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBidsAvailableTransportersGet: async (pageNumber?: number, pageSize?: number, controlledTemperature?: boolean, allowsADR?: boolean, location?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Bids/availableTransporters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (controlledTemperature !== undefined) {
                localVarQueryParameter['ControlledTemperature'] = controlledTemperature;
            }

            if (allowsADR !== undefined) {
                localVarQueryParameter['AllowsADR'] = allowsADR;
            }

            if (location !== undefined) {
                localVarQueryParameter['Location'] = location;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConfirmBidCommand} [confirmBidCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBidsConfirmPost: async (confirmBidCommand?: ConfirmBidCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Bids/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmBidCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {string} [dealId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBidsGet: async (pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, dealId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Bids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['SortBy'] = sortBy;
            }

            if (sortType !== undefined) {
                localVarQueryParameter['SortType'] = sortType;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (dealId !== undefined) {
                localVarQueryParameter['DealId'] = dealId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ChangeBidStatusDto} [changeBidStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBidsIdChangeStatusPatch: async (id: string, changeBidStatusDto?: ChangeBidStatusDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBidsIdChangeStatusPatch', 'id', id)
            const localVarPath = `/api/Bids/{id}/change-status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeBidStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBidsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBidsIdDelete', 'id', id)
            const localVarPath = `/api/Bids/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBidsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBidsIdGet', 'id', id)
            const localVarPath = `/api/Bids/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dealId] 
         * @param {string} [transporterId] 
         * @param {string} [driverId] 
         * @param {string} [equipmentId] 
         * @param {string} [statusId] 
         * @param {number} [cost] 
         * @param {number} [parkingFee] 
         * @param {number} [price] 
         * @param {string} [loadingDate] 
         * @param {Array<File>} [bidFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBidsPost: async (dealId?: string, transporterId?: string, driverId?: string, equipmentId?: string, statusId?: string, cost?: number, parkingFee?: number, price?: number, loadingDate?: string, bidFiles?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Bids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (dealId !== undefined) { 
                localVarFormParams.append('DealId', dealId as any);
            }
    
            if (transporterId !== undefined) { 
                localVarFormParams.append('TransporterId', transporterId as any);
            }
    
            if (driverId !== undefined) { 
                localVarFormParams.append('DriverId', driverId as any);
            }
    
            if (equipmentId !== undefined) { 
                localVarFormParams.append('EquipmentId', equipmentId as any);
            }
    
            if (statusId !== undefined) { 
                localVarFormParams.append('StatusId', statusId as any);
            }
    
            if (cost !== undefined) { 
                localVarFormParams.append('Cost', cost as any);
            }
    
            if (parkingFee !== undefined) { 
                localVarFormParams.append('ParkingFee', parkingFee as any);
            }
    
            if (price !== undefined) { 
                localVarFormParams.append('Price', price as any);
            }
    
            if (loadingDate !== undefined) { 
                localVarFormParams.append('LoadingDate', loadingDate as any);
            }
                if (bidFiles) {
                bidFiles.forEach((element) => {
                    localVarFormParams.append('BidFiles', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [dealId] 
         * @param {string} [transporterId] 
         * @param {string} [driverId] 
         * @param {string} [equipmentId] 
         * @param {string} [statusId] 
         * @param {number} [cost] 
         * @param {number} [price] 
         * @param {number} [parkingFee] 
         * @param {string} [loadingDate] 
         * @param {Array<string>} [existingFileNames] 
         * @param {Array<string>} [toBeDeletedFileNames] 
         * @param {Array<File>} [bidFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBidsPut: async (id?: string, dealId?: string, transporterId?: string, driverId?: string, equipmentId?: string, statusId?: string, cost?: number, price?: number, parkingFee?: number, loadingDate?: string, existingFileNames?: Array<string>, toBeDeletedFileNames?: Array<string>, bidFiles?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Bids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (dealId !== undefined) { 
                localVarFormParams.append('DealId', dealId as any);
            }
    
            if (transporterId !== undefined) { 
                localVarFormParams.append('TransporterId', transporterId as any);
            }
    
            if (driverId !== undefined) { 
                localVarFormParams.append('DriverId', driverId as any);
            }
    
            if (equipmentId !== undefined) { 
                localVarFormParams.append('EquipmentId', equipmentId as any);
            }
    
            if (statusId !== undefined) { 
                localVarFormParams.append('StatusId', statusId as any);
            }
    
            if (cost !== undefined) { 
                localVarFormParams.append('Cost', cost as any);
            }
    
            if (price !== undefined) { 
                localVarFormParams.append('Price', price as any);
            }
    
            if (parkingFee !== undefined) { 
                localVarFormParams.append('ParkingFee', parkingFee as any);
            }
    
            if (loadingDate !== undefined) { 
                localVarFormParams.append('LoadingDate', loadingDate as any);
            }
                if (existingFileNames) {
                existingFileNames.forEach((element) => {
                    localVarFormParams.append('ExistingFileNames', element as any);
                })
            }

                if (toBeDeletedFileNames) {
                toBeDeletedFileNames.forEach((element) => {
                    localVarFormParams.append('ToBeDeletedFileNames', element as any);
                })
            }

                if (bidFiles) {
                bidFiles.forEach((element) => {
                    localVarFormParams.append('BidFiles', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BidsApi - functional programming interface
 * @export
 */
export const BidsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BidsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [controlledTemperature] 
         * @param {boolean} [allowsADR] 
         * @param {string} [location] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBidsAvailableTransportersGet(pageNumber?: number, pageSize?: number, controlledTemperature?: boolean, allowsADR?: boolean, location?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BidDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBidsAvailableTransportersGet(pageNumber, pageSize, controlledTemperature, allowsADR, location, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConfirmBidCommand} [confirmBidCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBidsConfirmPost(confirmBidCommand?: ConfirmBidCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBidsConfirmPost(confirmBidCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {string} [dealId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBidsGet(pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, dealId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BidDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBidsGet(pageNumber, pageSize, sortBy, sortType, search, dealId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ChangeBidStatusDto} [changeBidStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBidsIdChangeStatusPatch(id: string, changeBidStatusDto?: ChangeBidStatusDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBidsIdChangeStatusPatch(id, changeBidStatusDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBidsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBidsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBidsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BidDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBidsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [dealId] 
         * @param {string} [transporterId] 
         * @param {string} [driverId] 
         * @param {string} [equipmentId] 
         * @param {string} [statusId] 
         * @param {number} [cost] 
         * @param {number} [parkingFee] 
         * @param {number} [price] 
         * @param {string} [loadingDate] 
         * @param {Array<File>} [bidFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBidsPost(dealId?: string, transporterId?: string, driverId?: string, equipmentId?: string, statusId?: string, cost?: number, parkingFee?: number, price?: number, loadingDate?: string, bidFiles?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBidsPost(dealId, transporterId, driverId, equipmentId, statusId, cost, parkingFee, price, loadingDate, bidFiles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [dealId] 
         * @param {string} [transporterId] 
         * @param {string} [driverId] 
         * @param {string} [equipmentId] 
         * @param {string} [statusId] 
         * @param {number} [cost] 
         * @param {number} [price] 
         * @param {number} [parkingFee] 
         * @param {string} [loadingDate] 
         * @param {Array<string>} [existingFileNames] 
         * @param {Array<string>} [toBeDeletedFileNames] 
         * @param {Array<File>} [bidFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBidsPut(id?: string, dealId?: string, transporterId?: string, driverId?: string, equipmentId?: string, statusId?: string, cost?: number, price?: number, parkingFee?: number, loadingDate?: string, existingFileNames?: Array<string>, toBeDeletedFileNames?: Array<string>, bidFiles?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBidsPut(id, dealId, transporterId, driverId, equipmentId, statusId, cost, price, parkingFee, loadingDate, existingFileNames, toBeDeletedFileNames, bidFiles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BidsApi - factory interface
 * @export
 */
export const BidsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BidsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [controlledTemperature] 
         * @param {boolean} [allowsADR] 
         * @param {string} [location] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBidsAvailableTransportersGet(pageNumber?: number, pageSize?: number, controlledTemperature?: boolean, allowsADR?: boolean, location?: string, options?: any): AxiosPromise<BidDtoOrdinalPaginatedList> {
            return localVarFp.apiBidsAvailableTransportersGet(pageNumber, pageSize, controlledTemperature, allowsADR, location, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConfirmBidCommand} [confirmBidCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBidsConfirmPost(confirmBidCommand?: ConfirmBidCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiBidsConfirmPost(confirmBidCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {string} [dealId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBidsGet(pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, dealId?: string, options?: any): AxiosPromise<BidDtoOrdinalPaginatedList> {
            return localVarFp.apiBidsGet(pageNumber, pageSize, sortBy, sortType, search, dealId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ChangeBidStatusDto} [changeBidStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBidsIdChangeStatusPatch(id: string, changeBidStatusDto?: ChangeBidStatusDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiBidsIdChangeStatusPatch(id, changeBidStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBidsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiBidsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBidsIdGet(id: string, options?: any): AxiosPromise<BidDto> {
            return localVarFp.apiBidsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [dealId] 
         * @param {string} [transporterId] 
         * @param {string} [driverId] 
         * @param {string} [equipmentId] 
         * @param {string} [statusId] 
         * @param {number} [cost] 
         * @param {number} [parkingFee] 
         * @param {number} [price] 
         * @param {string} [loadingDate] 
         * @param {Array<File>} [bidFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBidsPost(dealId?: string, transporterId?: string, driverId?: string, equipmentId?: string, statusId?: string, cost?: number, parkingFee?: number, price?: number, loadingDate?: string, bidFiles?: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.apiBidsPost(dealId, transporterId, driverId, equipmentId, statusId, cost, parkingFee, price, loadingDate, bidFiles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [dealId] 
         * @param {string} [transporterId] 
         * @param {string} [driverId] 
         * @param {string} [equipmentId] 
         * @param {string} [statusId] 
         * @param {number} [cost] 
         * @param {number} [price] 
         * @param {number} [parkingFee] 
         * @param {string} [loadingDate] 
         * @param {Array<string>} [existingFileNames] 
         * @param {Array<string>} [toBeDeletedFileNames] 
         * @param {Array<File>} [bidFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBidsPut(id?: string, dealId?: string, transporterId?: string, driverId?: string, equipmentId?: string, statusId?: string, cost?: number, price?: number, parkingFee?: number, loadingDate?: string, existingFileNames?: Array<string>, toBeDeletedFileNames?: Array<string>, bidFiles?: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.apiBidsPut(id, dealId, transporterId, driverId, equipmentId, statusId, cost, price, parkingFee, loadingDate, existingFileNames, toBeDeletedFileNames, bidFiles, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiBidsAvailableTransportersGet operation in BidsApi.
 * @export
 * @interface BidsApiApiBidsAvailableTransportersGetRequest
 */
export interface BidsApiApiBidsAvailableTransportersGetRequest {
    /**
     * 
     * @type {number}
     * @memberof BidsApiApiBidsAvailableTransportersGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof BidsApiApiBidsAvailableTransportersGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {boolean}
     * @memberof BidsApiApiBidsAvailableTransportersGet
     */
    readonly controlledTemperature?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof BidsApiApiBidsAvailableTransportersGet
     */
    readonly allowsADR?: boolean

    /**
     * 
     * @type {string}
     * @memberof BidsApiApiBidsAvailableTransportersGet
     */
    readonly location?: string
}

/**
 * Request parameters for apiBidsConfirmPost operation in BidsApi.
 * @export
 * @interface BidsApiApiBidsConfirmPostRequest
 */
export interface BidsApiApiBidsConfirmPostRequest {
    /**
     * 
     * @type {ConfirmBidCommand}
     * @memberof BidsApiApiBidsConfirmPost
     */
    readonly confirmBidCommand?: ConfirmBidCommand
}

/**
 * Request parameters for apiBidsGet operation in BidsApi.
 * @export
 * @interface BidsApiApiBidsGetRequest
 */
export interface BidsApiApiBidsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof BidsApiApiBidsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof BidsApiApiBidsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof BidsApiApiBidsGet
     */
    readonly sortBy?: string

    /**
     * 
     * @type {string}
     * @memberof BidsApiApiBidsGet
     */
    readonly sortType?: string

    /**
     * 
     * @type {string}
     * @memberof BidsApiApiBidsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof BidsApiApiBidsGet
     */
    readonly dealId?: string
}

/**
 * Request parameters for apiBidsIdChangeStatusPatch operation in BidsApi.
 * @export
 * @interface BidsApiApiBidsIdChangeStatusPatchRequest
 */
export interface BidsApiApiBidsIdChangeStatusPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof BidsApiApiBidsIdChangeStatusPatch
     */
    readonly id: string

    /**
     * 
     * @type {ChangeBidStatusDto}
     * @memberof BidsApiApiBidsIdChangeStatusPatch
     */
    readonly changeBidStatusDto?: ChangeBidStatusDto
}

/**
 * Request parameters for apiBidsIdDelete operation in BidsApi.
 * @export
 * @interface BidsApiApiBidsIdDeleteRequest
 */
export interface BidsApiApiBidsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof BidsApiApiBidsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiBidsIdGet operation in BidsApi.
 * @export
 * @interface BidsApiApiBidsIdGetRequest
 */
export interface BidsApiApiBidsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof BidsApiApiBidsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiBidsPost operation in BidsApi.
 * @export
 * @interface BidsApiApiBidsPostRequest
 */
export interface BidsApiApiBidsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof BidsApiApiBidsPost
     */
    readonly dealId?: string

    /**
     * 
     * @type {string}
     * @memberof BidsApiApiBidsPost
     */
    readonly transporterId?: string

    /**
     * 
     * @type {string}
     * @memberof BidsApiApiBidsPost
     */
    readonly driverId?: string

    /**
     * 
     * @type {string}
     * @memberof BidsApiApiBidsPost
     */
    readonly equipmentId?: string

    /**
     * 
     * @type {string}
     * @memberof BidsApiApiBidsPost
     */
    readonly statusId?: string

    /**
     * 
     * @type {number}
     * @memberof BidsApiApiBidsPost
     */
    readonly cost?: number

    /**
     * 
     * @type {number}
     * @memberof BidsApiApiBidsPost
     */
    readonly parkingFee?: number

    /**
     * 
     * @type {number}
     * @memberof BidsApiApiBidsPost
     */
    readonly price?: number

    /**
     * 
     * @type {string}
     * @memberof BidsApiApiBidsPost
     */
    readonly loadingDate?: string

    /**
     * 
     * @type {Array<File>}
     * @memberof BidsApiApiBidsPost
     */
    readonly bidFiles?: Array<File>
}

/**
 * Request parameters for apiBidsPut operation in BidsApi.
 * @export
 * @interface BidsApiApiBidsPutRequest
 */
export interface BidsApiApiBidsPutRequest {
    /**
     * 
     * @type {string}
     * @memberof BidsApiApiBidsPut
     */
    readonly id?: string

    /**
     * 
     * @type {string}
     * @memberof BidsApiApiBidsPut
     */
    readonly dealId?: string

    /**
     * 
     * @type {string}
     * @memberof BidsApiApiBidsPut
     */
    readonly transporterId?: string

    /**
     * 
     * @type {string}
     * @memberof BidsApiApiBidsPut
     */
    readonly driverId?: string

    /**
     * 
     * @type {string}
     * @memberof BidsApiApiBidsPut
     */
    readonly equipmentId?: string

    /**
     * 
     * @type {string}
     * @memberof BidsApiApiBidsPut
     */
    readonly statusId?: string

    /**
     * 
     * @type {number}
     * @memberof BidsApiApiBidsPut
     */
    readonly cost?: number

    /**
     * 
     * @type {number}
     * @memberof BidsApiApiBidsPut
     */
    readonly price?: number

    /**
     * 
     * @type {number}
     * @memberof BidsApiApiBidsPut
     */
    readonly parkingFee?: number

    /**
     * 
     * @type {string}
     * @memberof BidsApiApiBidsPut
     */
    readonly loadingDate?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof BidsApiApiBidsPut
     */
    readonly existingFileNames?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof BidsApiApiBidsPut
     */
    readonly toBeDeletedFileNames?: Array<string>

    /**
     * 
     * @type {Array<File>}
     * @memberof BidsApiApiBidsPut
     */
    readonly bidFiles?: Array<File>
}

/**
 * BidsApi - object-oriented interface
 * @export
 * @class BidsApi
 * @extends {BaseAPI}
 */
export class BidsApi extends BaseAPI {
    /**
     * 
     * @param {BidsApiApiBidsAvailableTransportersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidsApi
     */
    public apiBidsAvailableTransportersGet(requestParameters: BidsApiApiBidsAvailableTransportersGetRequest = {}, options?: AxiosRequestConfig) {
        return BidsApiFp(this.configuration).apiBidsAvailableTransportersGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.controlledTemperature, requestParameters.allowsADR, requestParameters.location, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BidsApiApiBidsConfirmPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidsApi
     */
    public apiBidsConfirmPost(requestParameters: BidsApiApiBidsConfirmPostRequest = {}, options?: AxiosRequestConfig) {
        return BidsApiFp(this.configuration).apiBidsConfirmPost(requestParameters.confirmBidCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BidsApiApiBidsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidsApi
     */
    public apiBidsGet(requestParameters: BidsApiApiBidsGetRequest = {}, options?: AxiosRequestConfig) {
        return BidsApiFp(this.configuration).apiBidsGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.sortBy, requestParameters.sortType, requestParameters.search, requestParameters.dealId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BidsApiApiBidsIdChangeStatusPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidsApi
     */
    public apiBidsIdChangeStatusPatch(requestParameters: BidsApiApiBidsIdChangeStatusPatchRequest, options?: AxiosRequestConfig) {
        return BidsApiFp(this.configuration).apiBidsIdChangeStatusPatch(requestParameters.id, requestParameters.changeBidStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BidsApiApiBidsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidsApi
     */
    public apiBidsIdDelete(requestParameters: BidsApiApiBidsIdDeleteRequest, options?: AxiosRequestConfig) {
        return BidsApiFp(this.configuration).apiBidsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BidsApiApiBidsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidsApi
     */
    public apiBidsIdGet(requestParameters: BidsApiApiBidsIdGetRequest, options?: AxiosRequestConfig) {
        return BidsApiFp(this.configuration).apiBidsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BidsApiApiBidsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidsApi
     */
    public apiBidsPost(requestParameters: BidsApiApiBidsPostRequest = {}, options?: AxiosRequestConfig) {
        return BidsApiFp(this.configuration).apiBidsPost(requestParameters.dealId, requestParameters.transporterId, requestParameters.driverId, requestParameters.equipmentId, requestParameters.statusId, requestParameters.cost, requestParameters.parkingFee, requestParameters.price, requestParameters.loadingDate, requestParameters.bidFiles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BidsApiApiBidsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidsApi
     */
    public apiBidsPut(requestParameters: BidsApiApiBidsPutRequest = {}, options?: AxiosRequestConfig) {
        return BidsApiFp(this.configuration).apiBidsPut(requestParameters.id, requestParameters.dealId, requestParameters.transporterId, requestParameters.driverId, requestParameters.equipmentId, requestParameters.statusId, requestParameters.cost, requestParameters.price, requestParameters.parkingFee, requestParameters.loadingDate, requestParameters.existingFileNames, requestParameters.toBeDeletedFileNames, requestParameters.bidFiles, options).then((request) => request(this.axios, this.basePath));
    }
}
