import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Content, DropdownStyle } from "./dropdown-style";

const Dropdown = ({
  content = defaultContent,
  placement = "bottomRight",
  title,
  action = ["hover"],
  children,
  style = {},
  className = "strikingDash-dropdown",
}) => {
  // Convert content to menu items format
  const menuItems = {
    items: [{ key: "1", label: content }],
  };

  return (
    <DropdownStyle
      overlayClassName={className}
      style={style}
      placement={placement}
      title={title}
      menu={menuItems}
      trigger={action}
    >
      {children}
    </DropdownStyle>
  );
};

const defaultContent = (
  <>
    <Link to="#">
      <span>Export to CSV</span>
    </Link>
    <Link to="#">
      <span>Export to XML</span>
    </Link>
    <Link to="#">
      <span>Export to Drive</span>
    </Link>
  </>
);

Dropdown.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string,
  action: PropTypes.array,
  content: PropTypes.node,
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
};

export { Dropdown };
