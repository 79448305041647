import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form, Spin, message } from "antd";

import { WizardFour, WizardWrapper } from "pages/style";
import UserForm from "pages/users/UserForm";

import { PageHeader } from "components/page-headers/page-headers";
import { Cards } from "components/cards/frame/cards-frame";
import { Steps } from "components/steps/steps";
import Company from "components/company/company";

import { CardToolbox, Main } from "container/styled";
import openNotificationWithIcon from "utility/notification";

import { CompaniesApi } from "api/api";
import { CompanyStatus, CompanyTypeEnum } from "api/models";
import { base64ToFile } from "utility/dowloadFiles";

const companiesApi = new CompaniesApi();

interface CreateCompanyPageProps {
  type: CompanyTypeEnum;
  isCreateMode?: boolean;
  onFinish?: () => void;
}

const CreateCompanyPage: React.FC<CreateCompanyPageProps> = ({
  type,
  isCreateMode,
  onFinish,
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [firstStepData, setFirstStepData] = useState<any>();
  const [status, setStatus] = useState<CompanyStatus>(CompanyStatus.NUMBER_1);

  const [fileList, setFileList] = useState<any[]>([]);
  const [existingFiles, setExistingFiles] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVerifyLoading, setIsVerifyLoading] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const companyId = searchParams.get("companyId") || "";
  const isEditMode = searchParams.get("upsertMode") === "true" || isCreateMode;

  const [currentStep, setCurrentStep] = useState(0);
  const getPageTitle = (type: any, t: any) => {
    switch (type) {
      case CompanyTypeEnum.NUMBER_0:
        return t("transporter:title.add", "Add Transporter");
      case CompanyTypeEnum.NUMBER_1:
        return t("clients:title.add", "Add Client");
      case CompanyTypeEnum.NUMBER_2:
        return t("freightForwarder:title.add", "Add Freight Forwarder");
      case CompanyTypeEnum.NUMBER_3:
        return t("shipper:title.add", "Add Shipper");
      default:
        return t("default:title.add", "Add Company");
    }
  };

  const handleFinish = async () => {
    setIsLoading(true);
    try {
      const values = await form.getFieldsValue();
      const userEmail = values.email;

      if (isEditMode && companyId) {
        await companiesApi.apiCompaniesIdPut({
          ...values,
          id: companyId,
          id2: companyId,
          type,
          existingFileNames: [...existingFiles]
            .filter((x) => !x.deleted)
            .map((x) => x.fileName),
          toBeDeletedFileNames: [...existingFiles]
            .filter((x) => x.deleted)
            .map((x) => x.fileName),
          companyFiles: fileList,
          userEmail: userEmail,
        });
        openNotificationWithIcon(
          "success",
          t(
            "create-company:company-edited-success",
            "Company is edited successfully!"
          )
        );
      } else if (isCreateMode || isEditMode) {
        await companiesApi.apiCompaniesPost({
          ...firstStepData,
          ...values,
          type,
          companyFiles: fileList,
          userEmail: userEmail,
        });
        openNotificationWithIcon(
          "success",
          t(
            "create-company:record-created-success",
            "Record is created successfully!"
          )
        );
        if (onFinish) {
          onFinish();
        }
      }
      if (!onFinish) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error creating/editing company:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onNext = async () => {
    const values = await form.getFieldsValue();
    setFirstStepData(values);
    setCurrentStep(currentStep + 1);
  };

  const onPrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleOnVerify = async (item: any, status: CompanyStatus) => {
    try {
      setIsVerifyLoading(true);
      await companiesApi.apiCompaniesIdChangeStatusPatch({
        id: companyId,
        changeCompanyStatusDto: { status: CompanyStatus.NUMBER_1 },
      });
      navigate(-1);
    } catch (error) {
      console.error("Error verifying company status:", error);
    } finally {
      setIsVerifyLoading(false);
    }
  };

  const fetchCompanyDetails = async () => {
    try {
      setIsLoading(true);
      const response = await companiesApi.apiCompaniesIdGet({
        id: companyId,
      });
      setExistingFiles(
        response.data?.files?.map((file) => ({
          fileName: file.fileName,
          deleted: false,
        })) ?? []
      );
      setFileList(
        !isEditMode
          ? response.data?.files ?? []
          : response.data?.files?.map(base64ToFile) ?? []
      );

      setStatus(response.data.status as CompanyStatus);

      form.setFieldsValue(response.data);
    } catch (error) {
      console.error("Error fetching company details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const steps = [
    {
      title: t("freightForwarder:tab:company", "Company"),
      content: isLoading ? (
        <div
          style={{
            display: "flex",
            height: 400,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <Company
          fileList={fileList}
          setFileList={setFileList}
          isViewMode={!isEditMode}
          existingFiles={existingFiles}
          status={status}
          handleOnVerify={handleOnVerify}
          setExistingFiles={setExistingFiles}
          isVerifyLoading={isVerifyLoading}
        />
      ),
    },
    ...(!companyId
      ? [
          {
            title: t("freightForwarder:tab:admin", "Admin"),
            content: <UserForm form={form} />,
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (companyId) {
      fetchCompanyDetails();
    }
  }, [companyId]);

  const handleOnDone = () => {
    if (isLoading) return message.loading("Processing...");
    form.submit();
    return message.success("Processing complete!");
  };

  return (
    <div>
      <CardToolbox>
        <PageHeader ghost title={getPageTitle(type, t)} />
      </CardToolbox>
      <Main>
        <Cards headless>
          <Form
            layout="vertical"
            form={form}
            name="company"
            onFinish={handleFinish}
          >
            <WizardWrapper>
              <WizardFour>
                <Steps
                  isvertical
                  isswitch
                  key={currentStep}
                  current={currentStep}
                  isLoading={isLoading}
                  direction="vertical"
                  isfinished={!isEditMode}
                  steps={steps.map((step, index) => ({
                    title: step.title,
                    content: (
                      <div
                        style={{
                          display: currentStep === index ? "block" : "none",
                        }}
                      >
                        {step.content}
                      </div>
                    ),
                  }))}
                  onNext={onNext}
                  onPrev={onPrev}
                  onDone={handleOnDone}
                />
              </WizardFour>
            </WizardWrapper>
          </Form>
        </Cards>
      </Main>
    </div>
  );
};

export default CreateCompanyPage;
