import { Button, Card, Col, Pagination, Row, Spin, message } from "antd";
import { BidsApi, DealsApi } from "api/api";
import { useEffect, useState } from "react";
import { BasicFormWrapper, Main } from "container/styled";
import { ButtonGroup } from "components/datePicker/style";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useModalState } from "hooks/useModalState";
import Bid from "components/bid/Bid";
import { hasPermission } from "utility/accessibility/hasPermission";
import { DealDto, DealStatusEnum } from "api/models";
import { useBidsFetch } from "components/bid/hooks/useBidsFetch";
import styled from "styled-components";
import { isSimpleUser } from "utility/isCompanyUser";

const ConfirmedBadge = styled.span`
  padding: 3px 6px;
  border-radius: 10px;
  background: #c0dd77;
  color: #7aa809;
`;

const dealsApi = new DealsApi();
const bidsApi = new BidsApi();

function generateDealHeadline(deal: DealDto): string {
  const { consignee, shipper, pickLocation, dropLocation } = deal;
  const typeOfTransport = deal.dedicatedTruck
    ? "Dedicated Truck"
    : "Regular Transport";
  return `Offer // ${pickLocation} - ${dropLocation} // ${typeOfTransport} // ${consignee?.businessName} // ${shipper?.businessName}`;
}

function UserShipments() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [deals, setDeals] = useState<DealDto[]>([]);
  const [selectedTransporterName, setSelectedTransporterName] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [currentDealId, setCurrentDealId] = useState<any>(null);
  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 10,
    search: "",
    sortBy: "",
    sortType: "",
  });
  const [totalCount, setTotalCount] = useState(0);

  const { fetchBids } = useBidsFetch({ currentDealId });
  const { modalState, closeModal, handleAddClick } = useModalState(fetchBids);

  useEffect(() => {
    fetchDeals();
  }, [query]);

  const fetchDeals = async () => {
    try {
      setIsLoading(true);
      const response: any = await dealsApi.apiDealsGet({
        ...query,
        status: undefined,
        mineOnly: true,
      });
      const dealsData = response.data.items;
      
      dealsData.map(async (deal: DealDto) => {
        if (deal.selectedBidId) {
          const bidResponse = await bidsApi.apiBidsIdGet({
            id: deal.selectedBidId,
          });

          const transporter = bidResponse.data.transporterName;
          setSelectedTransporterName(transporter);
        }
      });
      setDeals(response.data.items);
      setTotalCount(response.data.totalCount);
    } catch (error) {
      message.error("Failed to fetch deals");
      console.error("Error fetching deals:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClick = (id: string) => {
    navigate(`/deal/${id}/edit`);
  };

  const handleViewClick = (id: string) => {
    navigate(`/deal/${id}`);
  };

  const handlePlaceBidClick = (dealId: string) => {
    setCurrentDealId(dealId);
    handleAddClick();
  };

  return (
    <div style={{ width: '100%' }}>
      {isLoading ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Spin />
        </div>
      ) : deals.length === 0 ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          No shipments found
        </div>
      ) : (
        <Row justify="center">
          <Col span={24}>
            {deals.map((deal) => (
              <Card
                key={deal.id}
                title={generateDealHeadline(deal)}
                style={{ marginBottom: '16px' }}
                extra={
                  <ButtonGroup style={{ borderTop: "none" }}>
                    {hasPermission("users:list") && (
                      <Button
                        type="link"
                        onClick={() => handleViewClick(deal.id!)}
                      >
                        View
                      </Button>
                    )}
                    <Button
                      type="link"
                      onClick={() => handleEditClick(deal.id!)}
                    >
                      Edit
                    </Button>
                  </ButtonGroup>
                }
              >
                <Row gutter={[0, 10]}>
                  <Col span={4}>
                    <strong>
                      {t("deals-list.deal-commodity", "Commodity")}
                    </strong>
                  </Col>
                  <Col>{deal.commodity}</Col>
                  <Col span={4}>
                    <span></span>
                  </Col>
                </Row>
                <Row gutter={[0, 10]}>
                  <Col span={4}>
                    <strong>{t("deals-list.status", "Status")}</strong>
                  </Col>
                  <Col>
                    <span>
                      {deal.status === DealStatusEnum.NUMBER_2 ? (
                        <ConfirmedBadge>✓ CONFIRMED</ConfirmedBadge>
                      ) : (
                        <p>QUOTING</p>
                      )}
                    </span>
                  </Col>
                  <Col span={4}>
                    <span></span>
                  </Col>
                </Row>
                <Row gutter={[0, 10]}>
                  <Col span={4}>
                    {deal.status === DealStatusEnum.NUMBER_2 ? (
                      <strong>
                        {t("deals-list.transporter", "Transporter")}
                      </strong>
                    ) : (
                      <strong>
                        {t("deals-list.createdBy", "Created By")}
                      </strong>
                    )}
                  </Col>
                  <Col>
                    <span>
                      {deal.status === DealStatusEnum.NUMBER_2
                        ? selectedTransporterName
                        : deal.createdBy}
                    </span>
                  </Col>
                  <Col span={4}>
                    <span></span>
                  </Col>
                </Row>
                <ButtonGroup style={{ borderTop: "none" }}>
                  {isSimpleUser() &&
                  deal.status !== DealStatusEnum.NUMBER_2 ? (
                    <Button
                      type="primary"
                      className="btn-add_new"
                      key="add-codebook"
                      onClick={() => handlePlaceBidClick(deal.id!)}
                    >
                      {isSimpleUser()
                        ? t("deals.place-bid", "Place a bid")
                        : t("deals.apply", "Apply")}
                    </Button>
                  ) : null}
                </ButtonGroup>
              </Card>
            ))}
            <Pagination
              current={query.pageNumber}
              total={totalCount}
              showSizeChanger
              pageSizeOptions={["10", "50", "100", "1000"]}
              onChange={(pageNumber, pageSize) =>
                setQuery((prev) => ({ ...prev, pageNumber, pageSize }))
              }
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              style={{ textAlign: "center", marginTop: 20 }}
            />
          </Col>
        </Row>
      )}

      <Bid
        isVisible={modalState.addModalVisible}
        onHide={closeModal}
        mode={modalState.mode as any}
        bidToEdit={modalState.itemForEditModal}
        onSave={() => {
          fetchDeals();
          fetchBids();
        }}
        t={t}
        dealId={currentDealId}
      />
    </div>
  );
}

export default UserShipments;
