import {
  Checkbox,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Table,
} from "antd";
import { Button } from "components/buttons/buttons";
import { Cards } from "components/cards/frame/cards-frame";
import { Modal } from "components/modals/antd-modals";
import { PageHeader } from "components/page-headers/page-headers";
import { theme } from "config/theme/themeVariables";
import {
  AddProfile,
  BasicFormWrapper,
  CardToolbox,
  Main,
  ProfilePageHeaderStyle,
  ProfileTableStyleWrapper,
  TableWrapper,
} from "container/styled";
import FeatherIcon from "feather-icons-react";
import { useAuthorization } from "hooks/useAuthorization";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import openNotificationWithIcon from "utility/notification";
import { LanguagesApi } from "../../../api";
// import { Checkbox } from "components/checkbox/checkbox";

const languagesApi = new LanguagesApi();

const initialFormValues = {
  id: 0,
  country: "",
  isDefault: false,
  i18nCodeId: 0,
};

const SystemLanguages = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { hasPermission } = useAuthorization();

  const [state, setState] = useState({
    selectedRowKeys: 0,
    selectedRows: 0,
    visible: false,
    editVisible: false,
    modalType: "primary",
    url: null,
    language: initialFormValues,
    loading: true,
  });

  const [languages, setLanguages] = useState([]);
  const [languageCodes, setLanguageCodes] = useState([]);
  const [languageSubmitLoading, setLanguageSubmitLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setState({ loading: true });
    await getLanguages();
    await getLanguageCodes();
    setState({ loading: false });
  };

  const getLanguages = async () => {
    const { data } = await languagesApi.apiLanguagesGet();
    setLanguages(data);
  };

  const getLanguageCodes = async () => {
    const { data } = await languagesApi.apiLanguagesI18nCodesGet();
    setLanguageCodes(data);
  };

  const onCancel = () => {
    setState({
      ...state,
      visible: false,
      editVisible: false,
    });
  };

  const handleSubmit = async (values) => {
    values.position = parseInt(values.position);
    try {
      setLanguageSubmitLoading(true);
      const response = await languagesApi.apiLanguagesPost({
        addLanguageCommand: values,
      });
      if (response.status === 200) {
        openNotificationWithIcon(
          "success",
          t("system-languages.success.add", "Language was created successfully")
        );
        fetchData();
      }
    } catch (error) {
    } finally {
      setLanguageSubmitLoading(false);
      onCancel();
    }
  };

  const renderLanguagesInDropDown = () => {
    return languageCodes.map((language) => (
      <Select.Option key={language.id} value={language.id}>
        {t(`global.language.${language.name}`, language.name)}
      </Select.Option>
    ));
  };

  const handleEditSubmit = async (values) => {
    values.position = parseInt(values.position);
    try {
      setLanguageSubmitLoading(true);

      await languagesApi.apiLanguagesIdPut({
        id: values?.id,
        updateLanguageCommand: values,
      });

      openNotificationWithIcon(
        "success",
        t("system-languages.success.edit", "Language was updated successfully")
      );
      onCancel();

      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
    } finally {
      setLanguageSubmitLoading(false);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const onDeleteConfirm = async (id) => {
    try {
      await languagesApi.apiLanguagesIdDelete({ id });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {}
  };

  const showModal = () => {
    setState({
      ...state,
      visible: true,
    });

    form.resetFields();
  };

  const showEditModal = (language) => {
    setState({
      ...state,
      editVisible: true,
      language,
    });
    form.setFieldsValue(responseToFormModel(language));
  };

  const responseToFormModel = (model) => {
    return {
      id: model.id,
      position: model.position,
      country: model.i18nCode.name,
      i18nCodeId: model.i18nCodeId,
      isDefault: model.isDefault,
    };
  };

  const languagesTableData = languages.map((language) => {
    const { id, position, i18nCode, hasTranslations, isDefault } = language;

    return {
      key: id,
      position,
      country: t(`global.language.${i18nCode.name}`, i18nCode.name),
      isDefault: isDefault ? (
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <FeatherIcon size={20} icon="check" color={theme["primary-color"]} />
        </div>
      ) : null,
      action: (
        <div
          key={id}
          className="table-actions"
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button className="btn-icon" type="primary" to="#" shape="circle">
            <Link onClick={() => showEditModal(language)} to="#">
              <FeatherIcon icon="edit" size={16} />
            </Link>
          </Button>
          {hasPermission("languages:delete") ? (
            <>
              {/* {!hasTranslations && ( */}
              <Popconfirm
                title={t(
                  "system-languages.delete-language-confirmation",
                  "Are you sure delete this Language?"
                )}
                onConfirm={() => onDeleteConfirm(id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  className="btn-icon"
                  type="danger"
                  to="#"
                  shape="circle"
                >
                  <FeatherIcon icon="trash-2" size={16} />
                </Button>
              </Popconfirm>
            </>
          ) : null}
        </div>
      ),
    };
  });

  const columns = [
    {
      title: t("global.position", "Position"),
      dataIndex: "position",
      key: "position",
    },
    {
      title: t("global.country", "Country"),
      dataIndex: "country",
      key: "country",
    },
    {
      title: t("global.default", "Default"),
      key: "isDefault",
      dataIndex: "isDefault",
      width: "50px",
    },
    {
      title: "",
    },
    {
      title: t("global.actions", "Actions"),
      dataIndex: "action",
      key: "action",
      width: "90px",
    },
  ];

  return (
    <>
      <CardToolbox>
        <ProfilePageHeaderStyle>
          <PageHeader
            ghost
            title={t("system-languages.title", "Languages")}
            buttons={[
              hasPermission("languages:add") && (
                <Button
                  onClick={showModal}
                  className="btn-add_new"
                  size="default"
                  type="primary"
                  key="add-language"
                >
                  {t("system-languages.add", "Add New Language")}
                </Button>
              ),
            ]}
          />
        </ProfilePageHeaderStyle>
      </CardToolbox>

      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <ProfileTableStyleWrapper>
                <div className="contact-table">
                  <TableWrapper className="table-responsive">
                    <Table
                      loading={state.loading}
                      dataSource={languagesTableData}
                      columns={columns}
                      rowKey="key"
                    />
                  </TableWrapper>
                </div>
              </ProfileTableStyleWrapper>
            </Cards>
          </Col>
        </Row>
        <Modal
          type={state.modalType}
          title={t("system-languages.modal.title", "Add New Language")}
          visible={state.visible}
          footer={null}
          onCancel={handleCancel}
        >
          <div className="project-modal">
            <AddProfile>
              <BasicFormWrapper>
                <Form form={form} name="language" onFinish={handleSubmit}>
                  <Form.Item label="Id" name="id" hidden>
                    <Input placeholder="Id" />
                  </Form.Item>

                  <Form.Item
                    label={t("global.position", "Position")}
                    name="position"
                  >
                    <Input
                      placeholder={t("global.position", "Position")}
                      type="number"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Choose language to install"
                    name="i18nCodeId"
                  >
                    <Select
                      showSearch
                      placeholder="Choose a new language to install"
                      filterOption={(input, option) =>
                        option.props.children
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }
                    >
                      {renderLanguagesInDropDown()}
                    </Select>
                  </Form.Item>

                  <Form.Item name="isDefault" valuePropName="checked">
                    <Checkbox>
                      {t(
                        "system-languages.default-language",
                        "Default Language"
                      )}
                    </Checkbox>
                  </Form.Item>
                  <Button
                    disabled={languageSubmitLoading}
                    htmlType="submit"
                    size="default"
                    loading={languageSubmitLoading}
                    type="primary"
                    key="submit"
                  >
                    {t("system-languages.modal.add", "Add New Language")}
                  </Button>
                </Form>
              </BasicFormWrapper>
            </AddProfile>
          </div>
        </Modal>
        <Modal
          type={state.modalType}
          title={t("system-languages.editmodal.title", "Edit Language")}
          visible={state.editVisible}
          footer={null}
          onCancel={handleCancel}
        >
          <div className="project-modal">
            <AddProfile>
              <BasicFormWrapper>
                <Form
                  form={form}
                  name="languageEdit"
                  onFinish={handleEditSubmit}
                >
                  <Form.Item label="Id" name="id" hidden>
                    <Input placeholder="Id" />
                  </Form.Item>

                  <Form.Item label="i18nCodeId" name="i18nCodeId" hidden>
                    <Input placeholder="i18nCodeId" />
                  </Form.Item>

                  <Form.Item
                    label={t("global.position", "Position")}
                    name="position"
                  >
                    <Input placeholder="Position" type="number" />
                  </Form.Item>

                  <Form.Item
                    label={t("global.country", "Counry")}
                    name="country"
                  >
                    <Select
                      disabled
                      value={form.getFieldValue("i18nCodeId")}
                      placeholder="Name"
                    >
                      {renderLanguagesInDropDown()}
                    </Select>
                  </Form.Item>

                  <Form.Item name="isDefault" valuePropName="checked">
                    <Checkbox>
                      {t(
                        "system-languages.default-language",
                        "Default Language"
                      )}
                    </Checkbox>
                  </Form.Item>

                  <Button
                    htmlType="submit"
                    size="default"
                    type="primary"
                    disabled={languageSubmitLoading}
                    loading={languageSubmitLoading}
                    key="submit"
                  >
                    {t("system-languages.editmodal.edit", "Edit Language")}
                  </Button>
                </Form>
              </BasicFormWrapper>
            </AddProfile>
          </div>
        </Modal>
      </Main>
    </>
  );
};
export default SystemLanguages;
