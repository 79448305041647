import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Input, Row, DatePicker, Spin } from "antd";
import { BasicFormWrapper, CardToolbox, Main } from "container/styled";
import { useTranslation } from "react-i18next";
import { PageHeader } from "components/page-headers/page-headers";
import { CustomDateRangePicker } from "components/datePicker/datePickerCustom";
import { DealsApi } from "api/api";
import { ButtonGroup } from "components/datePicker/style";
import { useNavigate, useParams } from "react-router-dom";
import openNotificationWithIcon from "utility/notification";
import moment from "moment";
import CorridorsComponent from "components/deals/CorridorsComponent";
import MultiStepForm from "components/deals/MultiStepForm";
import { useFetchHSCodes } from "components/deals/hooks/useFetchHSCodes";
import BasicInformation from "components/deals/BasicInformation";

type ParamsType = {
  id?: string;
};
const dealsApi = new DealsApi();

function InitiateDealsPage() {
  const { t } = useTranslation();
  const params = useParams<ParamsType>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [addCorridor, setAddCorridor] = useState<(() => void) | null>(null);
  const [initialValues, setInitialValues] = useState<any>({});
  const [isTruckChecked, setIsTruckChecked] = useState(false);

  const addRef = useRef<(() => void) | null>(null);
  const hsCodes = useFetchHSCodes();
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dealId = params.id;
  const isEditMode = !!dealId;

  useEffect(() => {
    const fetchDealsId = async () => {
      if (dealId) {
        setIsLoading(true);
        try {
          const response = await dealsApi.apiDealsIdGet({ id: dealId });
          const data: any = response.data;

          const values = {
            ...data,
            shipperId: data.shipper.id,
            shipperTerminalId: data.shipperTerminal.id,
            consigneeId: data.consignee.id,
            consigneeTerminalId: data.consigneeTerminal.id,
            selectedTransporterId: data.selectedTransporterId,
          };

          form.setFieldsValue(values);
          setInitialValues(values);
          setIsTruckChecked(data.dedicatedTruck);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchDealsId();
  }, [dealId]);

  const onFinish = async (data: any) => {
    setIsSaving(true);

    const labels = {
      updated: t("initiate-deal:deal.updated", "Deal Updated"),
      created: t("initiate-deal:deal.created", "Deal Created"),
      updatedDescription: t(
        "initiate-deal:deal.updated.description",
        "Deal information have been updated"
      ),
      createdDescription: t(
        "initiate-deal:deal.created.description",
        "New deal has been successfully created!"
      ),
      updatedFailed: t("initiate-deal:failed.update.deal", "Update Failed!"),
      createdFailed: t(
        "initiate-deal:failed.create.deal",
        "Deal Creation Failed"
      ),
      updatedFailedDescription: t(
        "initiate-deal:failed.update.deal.description",
        "Deal couldn't be updated!"
      ),
      createdFailedDescription: t(
        "initiate-deal:failed.create.deal.description",
        "Deal couldn't be created!"
      ),
    };
    try {
      const response = isEditMode
        ? await dealsApi.apiDealsPut({
            updateDealCommand: {
              id: dealId,
              ...data,
              shippingStartsAt: moment(data?.shippingStartsAt).toDate(),
              shippingEndsAt: moment(data?.shippingEndsAt).toDate(),
            },
          })
        : await dealsApi.apiDealsPost({ addDealCommand: data });

      if (response.status >= 200 && response.status < 300) {
        openNotificationWithIcon(
          "success",
          isEditMode ? labels.updated : labels.created,
          isEditMode ? labels.updatedDescription : labels.createdDescription
        );
        navigate(`/newsfeed`);
      }
    } catch (err: any) {
      openNotificationWithIcon(
        "error",
        isEditMode ? labels.updatedFailed : labels.createdFailed,
        isEditMode
          ? labels.updatedFailedDescription
          : labels.createdFailedDescription
      );
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (addRef.current) {
      setAddCorridor(() => addRef.current);
    }
  }, []);

  const validateCargoReadyDate = (rule: any, value: any) => {
    const shippingStartsAt = form.getFieldValue("shippingStartsAt");
    const shippingEndsAt = form.getFieldValue("shippingEndsAt");
    if (
      shippingStartsAt &&
      shippingEndsAt &&
      value &&
      (moment(value).isSameOrBefore(moment(shippingStartsAt)) ||
        moment(value).isAfter(moment(shippingEndsAt)))
    ) {
      return Promise.reject(
        new Error(
          "Cargo ready date must be between the shipping start date and the shipping end date"
        )
      );
    }
    return Promise.resolve();
  };

  const handleTruckCheckboxChange = (e: any) => {
    setIsTruckChecked(e.target.checked);
  };

  const calculateVolume = (changedValues: any) => {
    const { length, height, width } = changedValues;
    if (length && height && width) {
      const calculatedVolume = (length * height * width) / 1000000;
      const formattedVolume = calculatedVolume.toFixed(5);
      console.log(formattedVolume);
      form.setFieldsValue({ volume: `${formattedVolume}` });
    }
  };

  return (
    <div>
      <CardToolbox>
        <PageHeader
          ghost
          title={
            !isEditMode
              ? t("initiate-deals:title", "Initiate Deals")
              : t("initiate-deals:edit", "Edit deal")
          }
        />
      </CardToolbox>
      <Main>
        <BasicFormWrapper>
          <div className="atbd-form-checkout">
            <Row justify="center">
              <Col xs={17}>
                <div className="create-account-form">
                  {isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "100vh",
                      }}
                    >
                      <Spin style={{ marginTop: -200 }} />
                    </div>
                  ) : (
                    <Form
                      form={form}
                      name="initiate-deal"
                      requiredMark
                      onFinish={onFinish}
                      onValuesChange={(changedValues, allValues) => {
                        if (
                          changedValues.hasOwnProperty("length") ||
                          changedValues.hasOwnProperty("height") ||
                          changedValues.hasOwnProperty("width")
                        ) {
                          calculateVolume(allValues);
                        }
                      }}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 30,
                      }}
                    >
                      <BasicInformation
                        hsCodes={hsCodes}
                        isTruckChecked={isTruckChecked}
                        handleTruckCheckboxChange={handleTruckCheckboxChange}
                      />
                      <Card
                        title={t(
                          "initiate-deal.pick-and-drop-location",
                          "Pick and Drop Location"
                        )}
                      >
                        <Form.Item
                          name="pickLocation"
                          label={t(
                            "initiate-deal.pick-Location",
                            "Pick Location"
                          )}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "initiate-deal:missing.pick-Location",
                                "Missing Pick Location"
                              ),
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="dropLocation"
                          label={t(
                            "initiate-deal.drop-Location",
                            "Drop Location"
                          )}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "initiate-deal:missing.drop-Location",
                                "Missing Drop Location"
                              ),
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Card>
                      <MultiStepForm form={form} />
                      <Card
                        title={t(
                          "initiate-deal.shipping-time-window",
                          "Shipping Time Window"
                        )}
                      >
                        <CustomDateRangePicker
                          onApply={(selection: any) => console.log(selection)}
                          form={form}
                          shippingStartsAt={form.getFieldValue(
                            "shippingStartsAt"
                          )}
                          shippingEndsAt={form.getFieldValue("shippingEndsAt")}
                        />
                        <Form.Item
                          name="shippingStartsAt"
                          style={{ display: "none" }}
                        >
                          <Input type="hidden" />
                        </Form.Item>
                        <Form.Item
                          name="shippingEndsAt"
                          style={{ display: "none" }}
                        >
                          <Input type="hidden" />
                        </Form.Item>
                        <Form.Item
                          name="cargoReadyDate"
                          getValueProps={(i) => ({ value: moment(i) })}
                          label={t(
                            "initiate-deal.cargo-date",
                            "Cargo Ready Date"
                          )}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "initiate-deal.select-cargo-date",
                                "Please select a  cargo ready date"
                              ),
                            },
                            {
                              validator: validateCargoReadyDate,
                            },
                          ]}
                        >
                          <DatePicker
                            format="YYYY-MM-DD"
                            defaultValue={moment(new Date())}
                            placeholder="Select cargo ready date"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Card>
                      <Card title={t("initiate-deal.corridors", "Corridors")}>
                        <Form.List name="corridors">
                          {(fields, { add, remove }) => {
                            addRef.current = add;
                            return (
                              <CorridorsComponent
                                fields={fields}
                                remove={remove}
                                t={t}
                              />
                            );
                          }}
                        </Form.List>
                        <Button
                          onClick={() => addCorridor && addCorridor()}
                          style={{ float: "right", marginTop: "10px" }}
                        >
                          Add +
                        </Button>
                      </Card>

                      <ButtonGroup style={{ marginBottom: "0px" }}>
                        <Button
                          type="link"
                          style={{
                            height: 50,
                            padding: "10px 20px",
                          }}
                          onClick={() => {
                            const corridors = form.getFieldValue("corridors");
                            if (isEditMode) {
                              form.setFieldsValue(initialValues);
                            } else {
                              form.resetFields();
                              if (corridors && corridors.length > 0) {
                                form.setFieldsValue({ corridors });
                              }
                            }
                          }}
                        >
                          {t("initiate-deal.cancel", "Cancel")}
                        </Button>

                        <Button
                          className="btn-next"
                          type="primary"
                          htmlType="submit"
                          loading={isSaving}
                          style={{ height: 50, padding: "10px 20px" }}
                        >
                          {!isEditMode
                            ? t("initiate-deal:submit", "Submit")
                            : t("initiate-deal:save", "Save")}
                        </Button>
                      </ButtonGroup>
                    </Form>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </BasicFormWrapper>
      </Main>
    </div>
  );
}

export default InitiateDealsPage;
