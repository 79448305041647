import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";
import { Col, Divider, Form, Input, Modal, Radio, Row, Spin } from "antd";
import { CompanyStatus, CompanyTypeEnum } from "../../api/models";
import {
  AddProfile,
  BasicFormWrapper,
  CardToolbox,
  Main,
  TopToolBox,
} from "../../container/styled";
import { PageHeader } from "../../components/page-headers/page-headers";
import { hasPermission } from "../../utility/accessibility/hasPermission";
import { Button } from "../../components/buttons/buttons";
import { ExportButtonPageHeader } from "../../components/buttons/export-button/export-button";
import { Cards } from "../../components/cards/frame/cards-frame";
import { AutoComplete } from "../../components/autoComplete/autoComplete";
import CompaniesTable from "../../components/company/companiesTable";
import React from "react";
import { useCompanyId } from "utility/useCompanyId";
import { ButtonStyled } from "components/buttons/styled";
import { CompaniesApi, CompanyRelationsApi } from "api/api";
import openNotificationWithIcon from "utility/notification";
import { useCompaniesFetch } from "components/company/hooks/useCompaniesFetch";

const companiesApi = new CompaniesApi();
const companyRelationApi = new CompanyRelationsApi();

const CompanyPage = ({ type }: any) => {
  const { t } = useTranslation();
  const [searchCompanies, setSearchCompanies] = useState("");
  const [filterType, setFilterType] = useState<CompanyStatus | string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [company, setCompany] = useState<any>(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const userCompanyId = useCompanyId();
  const navigate = useNavigate();
  const { query, fetchCompanies, data } = useCompaniesFetch({
    searchCompanies,
    filterType,
    companyType: type,
  });

  const [tableKey, setTableKey] = useState(0);

  const handleClear = () => {
    form.resetFields();
    setCompany(null);
  };

  const fetchCompanyDetails = async () => {
    if (company) {
      handleClear();
      return;
    }

    const businessNumber = form.getFieldValue("businessNumber");
    setLoading(true);

    try {
      const { data } = await companiesApi.apiCompaniesBusinessIdSearchGet({
        businessId: businessNumber,
      });
      if (data) {
        const existsResponse =
          await companyRelationApi.apiCompanyRelationsCheckExistsGet({
            primaryCompanyId: userCompanyId,
            relatedCompanyId: data.id,
          });

        const exists = existsResponse.data;

        if (exists) {
          openNotificationWithIcon(
            "warning",
            t(
              "companies:company-already-added",
              "You have already added this company to your list!"
            )
          );
          return;
        }

        setCompany(data);
        form.setFieldsValue(data);
      } else {
        handleAddNavigate();
      }
    } catch (err) {
      console.error("Failed to fetch company details:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (company) {
        await companyRelationApi.apiCompanyRelationsPost({
          createCompanyRelationRequest: { relatedCompanyId: company.id },
        });
        console.log("relation created");

        openNotificationWithIcon(
          "success",
          t(
            "companies:relation-create-success",
            "Relation created successfully"
          )
        );
        await fetchCompanies();
        setTableKey((prevKey) => prevKey + 1);
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        t("companies:relation-create-failed", "Relation creation failed")
      );
    } finally {
      setLoading(false);
      handleClear();
      handleModalCancel();
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    handleClear();
  };

  const filterKeys = [
    {
      id: CompanyStatus.NUMBER_1,
      name: t("company:select.verified", "Verified"),
    },
    {
      id: CompanyStatus.NUMBER_2,
      name: t("company:select.un-verified", "Un Verified"),
    },
    {
      id: CompanyStatus.NUMBER_3,
      name: t("company:select.deleted", "Deleted"),
    },
    {
      id: CompanyStatus.NUMBER_4,
      name: t("company:select.all", "All"),
    },
  ];

  const handleAddNavigate = () => {
    let path = "";

    switch (type) {
      case CompanyTypeEnum.NUMBER_0:
        path = "transporter";
        break;
      case CompanyTypeEnum.NUMBER_1:
        path = "client";
        break;
      case CompanyTypeEnum.NUMBER_2:
        path = "freight-forwarder";
        break;
      case CompanyTypeEnum.NUMBER_3:
        path = "shipper";
        break;
      default:
        path = "transporter";
        break;
    }

    navigate(`/add-${path}?upsertMode=true`);
  };

  const onSearchChange = (value: string) => {
    setSearchCompanies(value);
  };

  const onFilterChange = (e: any) => {
    setFilterType(e.target.value);
  };

  const pageTitle = useMemo(() => {
    switch (type) {
      case CompanyTypeEnum.NUMBER_0:
        return t("transporters:title", "Transporters");
      case CompanyTypeEnum.NUMBER_1:
        return t("clients:title", "Clients");
      case CompanyTypeEnum.NUMBER_2:
        return t("freight-forwarders:title", "Freight Forwarders");
      case CompanyTypeEnum.NUMBER_3:
        return t("shippers:title", "Shippers");
      default:
        return "";
    }
  }, [type, t]);

  const modalTitle = useMemo(() => {
    switch (type) {
      case CompanyTypeEnum.NUMBER_0:
        return t("transporters:title", "Add Transporter");
      case CompanyTypeEnum.NUMBER_1:
        return t("clients:title", "Add Client");
      case CompanyTypeEnum.NUMBER_2:
        return t("freight-forwarders:title", "Add Freight Forwarder");
      case CompanyTypeEnum.NUMBER_3:
        return t("shippers:title", "Add Shipper");
      default:
        return "";
    }
  }, [type, t]);

  const getPermission = (action: any) => {
    switch (type) {
      case CompanyTypeEnum.NUMBER_0:
        return `transporters:${action}`;
      case CompanyTypeEnum.NUMBER_1:
        return `clients:${action}`;
      case CompanyTypeEnum.NUMBER_2:
        return `freight-forwarders:${action}`;
      case CompanyTypeEnum.NUMBER_3:
        return `shippers:${action}`;
      default:
        return `transporters:${action}`;
    }
  };

  return (
    <div>
      <CardToolbox>
        <PageHeader
          ghost
          title={pageTitle}
          buttons={[
            <div key="__actions" className="page-header-actions">
              {hasPermission(getPermission("add")) && (
                <Button
                  onClick={showModal}
                  className="btn-add_new"
                  size="default"
                  type="white"
                  key="add-codebook"
                >
                  <FeatherIcon icon="plus" size={25} />
                  {t("companies:button.add", "Add New")}
                </Button>
              )}
              <ExportButtonPageHeader
                key="1"
                title={t("companies:title", "Companies")}
                data={data?.items}
                columns={[
                  "ordinalNumber",
                  "businessName",
                  "tradeName",
                  "businessNumber",
                  "fiscalNumber",
                  "address",
                  "phoneNumber",
                  "companyEmail",
                  "status",
                ]}
              />
            </div>,
          ]}
        />
      </CardToolbox>
      <Main>
        <Cards headless>
          <Row gutter={15}>
            <Col xs={24}>
              <TopToolBox>
                <Row gutter={15}>
                  {hasPermission(getPermission("search")) && (
                    <Col lg={6} md={6} xs={24}>
                      <div className="table-search-box">
                        <AutoComplete
                          onSearch={(value) => onSearchChange(value)}
                          width="100%"
                          patterns
                        />
                      </div>
                    </Col>
                  )}
                  <Col lg={12} md={14} xs={24}>
                    <div
                      className="table-toolbox-menu"
                      style={{ float: "left", marginLeft: 20 }}
                    >
                      <span className="toolbox-menu-title">
                        {t("users:label.status", "Status:")}
                      </span>
                      <Radio.Group
                        name="filterKey"
                        onChange={onFilterChange}
                        defaultValue={CompanyStatus.NUMBER_1}
                      >
                        {filterKeys.map((item) => (
                          <Radio.Button key={item.id} value={item.id}>
                            {item.name}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </div>
                  </Col>
                </Row>
              </TopToolBox>
            </Col>
          </Row>
          <Row>
            <CompaniesTable
              key={tableKey}
              searchCompanies={searchCompanies}
              filterType={filterType}
              companyType={type}
            />
          </Row>
        </Cards>
      </Main>
      {isModalVisible && (
        <Modal
          title={modalTitle}
          open={isModalVisible}
          footer={null}
          onCancel={handleModalCancel}
        >
          <div className="project-modal">
            <AddProfile>
              <BasicFormWrapper>
                <Form form={form} name="company" requiredMark>
                  <Form.Item
                    name="businessNumber"
                    label={t(
                      "companies:input.businessNumber",
                      "Business Number"
                    )}
                  >
                    <div style={{ display: "flex" }}>
                      <Input
                        placeholder={t(
                          "companies:input.businessNumber",
                          "Business Number"
                        )}
                        disabled={company || loading}
                      />
                      <ButtonStyled
                        style={{
                          height: 50,
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          marginLeft: -4,
                        }}
                        type={company ? "link" : "primary"}
                        onClick={fetchCompanyDetails}
                        disabled={loading}
                      >
                        {loading ? (
                          <Spin size="small" />
                        ) : (
                          <>
                            <FeatherIcon
                              icon={company ? "x" : "search"}
                              size={20}
                            />{" "}
                            &nbsp;{" "}
                            {company
                              ? t("companies:clear", "Clear")
                              : t("companies:search", "Search")}
                          </>
                        )}
                      </ButtonStyled>
                    </div>
                  </Form.Item>
                  {company && (
                    <div>
                      <div>
                        {t("companies:businessName", "Business Name")}:{" "}
                        <strong>{company?.businessName} </strong>
                      </div>
                      <div>
                        {t("companies:tradeName", "Trade Name")}:{" "}
                        <strong>{company?.tradeName}</strong>
                      </div>
                      <div>
                        {t("companies:businessNumber", "Business Number")}:{" "}
                        <strong>{company?.businessNumber}</strong>
                      </div>
                      <div>
                        {t("companies:fiscalNumber", "Fiscal Number")}:{" "}
                        <strong>{company?.fiscalNumber}</strong>
                      </div>
                      <div>
                        {t("companies:address", "Address")}:{" "}
                        <strong>{company?.address}</strong>
                      </div>
                      <div>
                        {t("companies:phoneNumber", "Phone Number")}:{" "}
                        <strong>{company?.phoneNumber}</strong>
                      </div>
                      <div>
                        {t("companies:companyEmail", "Email Address")}:{" "}
                        <strong>{company?.companyEmail}</strong>
                      </div>
                      <Divider />
                      <ButtonStyled
                        style={{
                          height: 50,
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          marginLeft: -4,
                          width: "100%",
                        }}
                        type={"primary"}
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        {loading ? (
                          <Spin size="small" />
                        ) : (
                          <>
                            <FeatherIcon icon={"check"} size={30} />{" "}
                            {t("companys:save", "Save")}
                          </>
                        )}
                      </ButtonStyled>
                    </div>
                  )}
                </Form>
              </BasicFormWrapper>
            </AddProfile>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CompanyPage;
