import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import FeatherIcon from "feather-icons-react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { NavLink, useResolvedPath } from "react-router-dom";
import { menuItemsList } from "utility/accessibility";
import { isMenuItemVisible } from "utility/accessibility/isMenuItemVisible";

const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { pathname } = useResolvedPath();
  const pathName = window.location.pathname;
  const pathArray = pathName?.split(pathname);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath?.split("/");

  const { t } = useTranslation();

  // State to hold the menu items
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const loadMenuItems = async () => {
      const items = await menuItemsList(t); // Assuming menuItemsList returns a promise
      setMenuItems(Object.values(items));
    };

    loadMenuItems();
  }, [t]); // Re-run when translation changes

  const defaultOpenKeys = !topMenu
    ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
    : [];

  const canSeeMenuItem = (item) => {
    if (item.claimName) {
      return isMenuItemVisible(item);
    }
    if (item.subMenuItems) {
      return item.subMenuItems.some((subItem) => canSeeMenuItem(subItem));
    }
    return true;
  };

  const getMenuItem = (item) => {
    if (!canSeeMenuItem(item)) return null;

    if (item.subMenuItems) {
      const subMenuItems = item.subMenuItems.map(getMenuItem).filter(Boolean);

      if (subMenuItems.length > 0) {
        return {
          key: item.key,
          icon: !topMenu && item.icon ? <FeatherIcon icon={item.icon} /> : null,
          label: item.label,
          children: subMenuItems,
        };
      }
    } else {
      return {
        key: item.key,
        icon: !topMenu && item.icon ? <FeatherIcon icon={item.icon} /> : null,
        label: (
          <NavLink
            className={({ isActive }) =>
              isActive ? "ant-menu-item-selected" : ""
            }
            to={item.path}
          >
            {item.label}
          </NavLink>
        ),
      };
    }
    return null;
  };

  const menuItemsConfig = menuItems.map(getMenuItem).filter(Boolean);

  return (
    <Menu
      mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
      theme={darkMode ? "dark" : "light"}
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1
                  ? "home"
                  : mainPathSplit.length === 2
                  ? mainPathSplit[1]
                  : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={defaultOpenKeys}
      items={menuItemsConfig}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
    >
      {/* No need to render items manually as Ant Design handles it */}
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: PropTypes.bool,
  toggleCollapsed: PropTypes.func,
  topMenu: PropTypes.bool,
};

export default MenuItems;
