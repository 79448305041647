import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import { Modal } from "components/modals/antd-modals";
import { useTranslation } from "react-i18next";
import { DestinationProps } from "components/destinations/destinationDataTableColumns";

import { DestinationsApi, CountriesApi } from "api/api";
import openNotificationWithIcon from "utility/notification";

const destinationApi = new DestinationsApi();
const countriesApi = new CountriesApi();

const { Option } = Select;

const Destination: React.FC<DestinationProps> = ({
  onSave,
  isVisible,
  onHide,
  companyId,
}) => {
  const { t } = useTranslation();

  const [countryData, setCountryData] = useState<any>([]);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const handleOnSave = async () => {
    setIsBtnLoading(true);
    try {
      const values = await form.validateFields();
      await destinationApi.apiCompaniesDestinationsPost({
        addDestinationsCommand: {
          ...values,
          companyId: companyId,
        },
      });
      openNotificationWithIcon(
        "success",
        t(
          "destinationModal:destination-created-success",
          "Destination is created successfuly!"
        )
      );
      form.resetFields();
      if (onHide && onSave) {
        onHide();
        onSave("" as any);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsBtnLoading(false);
    }
  };

  const fetchCountries = async (search: string = "") => {
    const response = await countriesApi.apiCountriesGet({
      pageSize: 1000,
      pageNumber: 1,
      search: search,
    });
    setCountryData(response.data.items);
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const handleOnCancel = () => {
    form.resetFields();
    if (onHide) {
      onHide();
    }
  };

  return (
    <>
      <Modal
        title={t("destination:add.title", "Add Destination")}
        visible={isVisible}
        onOk={handleOnSave}
        onCancel={handleOnCancel}
        type="primary"
        loading={isBtnLoading}
      >
        <Form form={form} layout="vertical" name="destinationForm">
          <Form.Item
            name="countries"
            label="Countries"
            rules={[{ required: true, message: "Please select one country!" }]}
          >
            <Select
              size="large"
              mode="multiple"
              allowClear
              showSearch
              filterOption={false}
              onSearch={(value) => fetchCountries(value)}
              defaultActiveFirstOption={false}
              optionLabelProp="label" // Use custom label rendering
            >
              {countryData?.map((item: any) => (
                <Option key={item.id} value={item.id} label={item.name}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      src={item.flag}
                      alt={`${item.name} flag`}
                      style={{
                        width: "20px",
                        height: "15px",
                        objectFit: "cover",
                      }}
                    />
                    <span>{item.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Destination;
